import axios from "axios";
import environment from "../../environment";
import {
  errorInterceptor,
  requestFormDataInterceptor,
  requestInterceptor
} from "../Interceptors";

export const customerInstance = axios.create({
  baseURL: environment.skwApiNewBaseUrl
});

customerInstance.interceptors.request.use(requestInterceptor);
customerInstance.interceptors.response.use((res) => res, errorInterceptor);

export const customerUploadInstance = axios.create({
  baseURL: environment.skwApiNewBaseUrl
});

customerUploadInstance.interceptors.request.use(requestFormDataInterceptor);
customerUploadInstance.interceptors.response.use(
  (res) => res,
  errorInterceptor
);
