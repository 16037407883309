import { QueryClient } from "@tanstack/react-query";
import { ApiError } from "../service/ErrorHandler";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, e) => {
        const error = e as ApiError;
        if (error.status == 401 || error.status == 404) {
          return false;
        } else return failureCount < 2;
      }
    }
  }
});
