/* tslint:disable */
/* eslint-disable */
/**
 * SkyWorker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomErrorResponse } from '../model';
// @ts-ignore
import { CustomerPostRequest } from '../model';
// @ts-ignore
import { CustomerPublicInfo } from '../model';
// @ts-ignore
import { CustomerPutRequest } from '../model';
// @ts-ignore
import { CustomerV3 } from '../model';
// @ts-ignore
import { PaginatedResponseCustomerV3 } from '../model';
/**
 * CustomerV3Api - axios parameter creator
 * @export
 */
export const CustomerV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new customer
         * @param {string} companyId 
         * @param {CustomerPostRequest} customerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerV3: async (companyId: string, customerPostRequest: CustomerPostRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCustomerV3', 'companyId', companyId)
            // verify required parameter 'customerPostRequest' is not null or undefined
            assertParamExists('createCustomerV3', 'customerPostRequest', customerPostRequest)
            const localVarPath = `/v3/company/{companyId}/customer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerV3: async (companyId: string, customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCustomerV3', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteCustomerV3', 'customerId', customerId)
            const localVarPath = `/v3/company/{companyId}/customer/{customerId}/`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} customerId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByIdV3: async (customerId: string, companyId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerByIdV3', 'customerId', customerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCustomerByIdV3', 'companyId', companyId)
            const localVarPath = `/v3/company/{companyId}/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByResourceIdV3: async (companyId: string, resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCustomerByResourceIdV3', 'companyId', companyId)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getCustomerByResourceIdV3', 'resourceId', resourceId)
            const localVarPath = `/v3/company/{companyId}/customer/resource/{resourceId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIdByResourceIdV3: async (resourceId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getCustomerIdByResourceIdV3', 'resourceId', resourceId)
            const localVarPath = `/v3/public/customer/resource/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public customer by id
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPublicInfoById: async (customerId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerPublicInfoById', 'customerId', customerId)
            const localVarPath = `/v3/customer/{customerId}/public`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by companyId
         * @param {string} companyId 
         * @param {'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference'} sortField 
         * @param {string} [authorization] 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Private' | 'Corporate'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersOnCompanyV3: async (companyId: string, sortField: 'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference', authorization?: string, searchQuery?: string, page?: number, sortDirection?: 'ASC' | 'DESC', type?: 'Private' | 'Corporate', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCustomersOnCompanyV3', 'companyId', companyId)
            // verify required parameter 'sortField' is not null or undefined
            assertParamExists('getCustomersOnCompanyV3', 'sortField', sortField)
            const localVarPath = `/v3/company/{companyId}/customer`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update primary address on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} addressId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPrimaryAddressV3: async (companyId: string, customerId: string, addressId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCustomerPrimaryAddressV3', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerPrimaryAddressV3', 'customerId', customerId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('updateCustomerPrimaryAddressV3', 'addressId', addressId)
            const localVarPath = `/v3/company/{companyId}/customer/{customerId}/address/{addressId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update primary contact on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} contactId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPrimaryContactV3: async (companyId: string, customerId: string, contactId: string, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCustomerPrimaryContactV3', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerPrimaryContactV3', 'customerId', customerId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('updateCustomerPrimaryContactV3', 'contactId', contactId)
            const localVarPath = `/v3/company/{companyId}/customer/{customerId}/contact/{contactId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {CustomerPutRequest} customerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerV3: async (companyId: string, customerId: string, customerPutRequest: CustomerPutRequest, authorization?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCustomerV3', 'companyId', companyId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('updateCustomerV3', 'customerId', customerId)
            // verify required parameter 'customerPutRequest' is not null or undefined
            assertParamExists('updateCustomerV3', 'customerPutRequest', customerPutRequest)
            const localVarPath = `/v3/company/{companyId}/customer/{customerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerV3Api - functional programming interface
 * @export
 */
export const CustomerV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new customer
         * @param {string} companyId 
         * @param {CustomerPostRequest} customerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerV3(companyId: string, customerPostRequest: CustomerPostRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerV3(companyId, customerPostRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerV3(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerV3(companyId, customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} customerId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerByIdV3(customerId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerByIdV3(customerId, companyId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerByResourceIdV3(companyId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerByResourceIdV3(companyId, resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerIdByResourceIdV3(resourceId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerIdByResourceIdV3(resourceId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public customer by id
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerPublicInfoById(customerId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPublicInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerPublicInfoById(customerId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by companyId
         * @param {string} companyId 
         * @param {'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference'} sortField 
         * @param {string} [authorization] 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Private' | 'Corporate'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomersOnCompanyV3(companyId: string, sortField: 'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference', authorization?: string, searchQuery?: string, page?: number, sortDirection?: 'ASC' | 'DESC', type?: 'Private' | 'Corporate', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseCustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomersOnCompanyV3(companyId, sortField, authorization, searchQuery, page, sortDirection, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update primary address on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} addressId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerPrimaryAddressV3(companyId: string, customerId: string, addressId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerPrimaryAddressV3(companyId, customerId, addressId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update primary contact on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} contactId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerPrimaryContactV3(companyId: string, customerId: string, contactId: string, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerPrimaryContactV3(companyId, customerId, contactId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {CustomerPutRequest} customerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerV3(companyId: string, customerId: string, customerPutRequest: CustomerPutRequest, authorization?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerV3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerV3(companyId, customerId, customerPutRequest, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerV3Api - factory interface
 * @export
 */
export const CustomerV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerV3ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new customer
         * @param {string} companyId 
         * @param {CustomerPostRequest} customerPostRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerV3(companyId: string, customerPostRequest: CustomerPostRequest, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.createCustomerV3(companyId, customerPostRequest, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerV3(companyId: string, customerId: string, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.deleteCustomerV3(companyId, customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} customerId 
         * @param {string} companyId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByIdV3(customerId: string, companyId: string, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.getCustomerByIdV3(customerId, companyId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} companyId 
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByResourceIdV3(companyId: string, resourceId: string, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.getCustomerByResourceIdV3(companyId, resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by resourceId
         * @param {string} resourceId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIdByResourceIdV3(resourceId: string, authorization?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getCustomerIdByResourceIdV3(resourceId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public customer by id
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPublicInfoById(customerId: string, authorization?: string, options?: any): AxiosPromise<CustomerPublicInfo> {
            return localVarFp.getCustomerPublicInfoById(customerId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by companyId
         * @param {string} companyId 
         * @param {'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference'} sortField 
         * @param {string} [authorization] 
         * @param {string} [searchQuery] 
         * @param {number} [page] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'Private' | 'Corporate'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomersOnCompanyV3(companyId: string, sortField: 'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference', authorization?: string, searchQuery?: string, page?: number, sortDirection?: 'ASC' | 'DESC', type?: 'Private' | 'Corporate', options?: any): AxiosPromise<PaginatedResponseCustomerV3> {
            return localVarFp.getCustomersOnCompanyV3(companyId, sortField, authorization, searchQuery, page, sortDirection, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update primary address on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} addressId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPrimaryAddressV3(companyId: string, customerId: string, addressId: string, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.updateCustomerPrimaryAddressV3(companyId, customerId, addressId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update primary contact on customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {string} contactId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPrimaryContactV3(companyId: string, customerId: string, contactId: string, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.updateCustomerPrimaryContactV3(companyId, customerId, contactId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer
         * @param {string} companyId 
         * @param {string} customerId 
         * @param {CustomerPutRequest} customerPutRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerV3(companyId: string, customerId: string, customerPutRequest: CustomerPutRequest, authorization?: string, options?: any): AxiosPromise<CustomerV3> {
            return localVarFp.updateCustomerV3(companyId, customerId, customerPutRequest, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerV3Api - object-oriented interface
 * @export
 * @class CustomerV3Api
 * @extends {BaseAPI}
 */
export class CustomerV3Api extends BaseAPI {
    /**
     * 
     * @summary Create new customer
     * @param {string} companyId 
     * @param {CustomerPostRequest} customerPostRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public createCustomerV3(companyId: string, customerPostRequest: CustomerPostRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).createCustomerV3(companyId, customerPostRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public deleteCustomerV3(companyId: string, customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).deleteCustomerV3(companyId, customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by id
     * @param {string} customerId 
     * @param {string} companyId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public getCustomerByIdV3(customerId: string, companyId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).getCustomerByIdV3(customerId, companyId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by resourceId
     * @param {string} companyId 
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public getCustomerByResourceIdV3(companyId: string, resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).getCustomerByResourceIdV3(companyId, resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by resourceId
     * @param {string} resourceId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public getCustomerIdByResourceIdV3(resourceId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).getCustomerIdByResourceIdV3(resourceId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public customer by id
     * @param {string} customerId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public getCustomerPublicInfoById(customerId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).getCustomerPublicInfoById(customerId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by companyId
     * @param {string} companyId 
     * @param {'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference'} sortField 
     * @param {string} [authorization] 
     * @param {string} [searchQuery] 
     * @param {number} [page] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'Private' | 'Corporate'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public getCustomersOnCompanyV3(companyId: string, sortField: 'CustomerNumber' | 'Type' | 'CompanyName' | 'OrgNumber' | 'ExternalReference', authorization?: string, searchQuery?: string, page?: number, sortDirection?: 'ASC' | 'DESC', type?: 'Private' | 'Corporate', options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).getCustomersOnCompanyV3(companyId, sortField, authorization, searchQuery, page, sortDirection, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update primary address on customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} addressId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public updateCustomerPrimaryAddressV3(companyId: string, customerId: string, addressId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).updateCustomerPrimaryAddressV3(companyId, customerId, addressId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update primary contact on customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {string} contactId 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public updateCustomerPrimaryContactV3(companyId: string, customerId: string, contactId: string, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).updateCustomerPrimaryContactV3(companyId, customerId, contactId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer
     * @param {string} companyId 
     * @param {string} customerId 
     * @param {CustomerPutRequest} customerPutRequest 
     * @param {string} [authorization] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerV3Api
     */
    public updateCustomerV3(companyId: string, customerId: string, customerPutRequest: CustomerPutRequest, authorization?: string, options?: AxiosRequestConfig) {
        return CustomerV3ApiFp(this.configuration).updateCustomerV3(companyId, customerId, customerPutRequest, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
