/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router"

// Import Routes

import { Route as rootRoute } from "./routes/__root"
import { Route as UnauthenticatedImport } from "./routes/_unauthenticated"
import { Route as ProtectedImport } from "./routes/_protected"
import { Route as UnauthenticatedIndexImport } from "./routes/_unauthenticated/index"
import { Route as UnauthenticatedResetPasswordImport } from "./routes/_unauthenticated/resetPassword"
import { Route as UnauthenticatedLoginImport } from "./routes/_unauthenticated/login"
import { Route as UnauthenticatedCreateAccountImport } from "./routes/_unauthenticated/createAccount"
import { Route as ProtectedWelcomeImport } from "./routes/_protected/welcome"
import { Route as ProtectedUserInfoImport } from "./routes/_protected/userInfo"
import { Route as ProtectedTrialImport } from "./routes/_protected/trial"
import { Route as ProtectedInvitationsImport } from "./routes/_protected/invitations"
import { Route as ProtectedDashboardImport } from "./routes/_protected/dashboard"
import { Route as ProtectedCreateCompanyImport } from "./routes/_protected/createCompany"
import { Route as GuestCustomerIdIndexImport } from "./routes/guest/$customerId/index"
import { Route as GuestProjectProjectIdImport } from "./routes/guest/project/$projectId"
import { Route as GuestOrderOrderIdImport } from "./routes/guest/order/$orderId"
import { Route as UnauthenticatedOfferTemplateImport } from "./routes/_unauthenticated/offer/template"
import { Route as UnauthenticatedOfferPreviewImport } from "./routes/_unauthenticated/offer/preview"
import { Route as ProtectedTrialSubscribeImport } from "./routes/_protected/trial/subscribe"
import { Route as ProtectedTrialExpiredImport } from "./routes/_protected/trial/expired"
import { Route as ProtectedTrialConfirmationImport } from "./routes/_protected/trial/confirmation"
import { Route as UnauthenticatedOfferPathUriIndexImport } from "./routes/_unauthenticated/offer/$pathUri/index"
import { Route as ProtectedDashboardTimeIndexImport } from "./routes/_protected/dashboard/time/index"
import { Route as ProtectedDashboardProjectIndexImport } from "./routes/_protected/dashboard/project/index"
import { Route as ProtectedDashboardProductsIndexImport } from "./routes/_protected/dashboard/products/index"
import { Route as ProtectedDashboardPricesIndexImport } from "./routes/_protected/dashboard/prices/index"
import { Route as ProtectedDashboardOverviewIndexImport } from "./routes/_protected/dashboard/overview/index"
import { Route as ProtectedDashboardOrdersIndexImport } from "./routes/_protected/dashboard/orders/index"
import { Route as ProtectedDashboardInvoiceIndexImport } from "./routes/_protected/dashboard/invoice/index"
import { Route as ProtectedDashboardEmployeesIndexImport } from "./routes/_protected/dashboard/employees/index"
import { Route as ProtectedDashboardEhsIndexImport } from "./routes/_protected/dashboard/ehs/index"
import { Route as ProtectedDashboardCustomersIndexImport } from "./routes/_protected/dashboard/customers/index"
import { Route as ProtectedDashboardActivitiesIndexImport } from "./routes/_protected/dashboard/activities/index"
import { Route as UnauthenticatedOfferPathUriReceiptImport } from "./routes/_unauthenticated/offer/$pathUri/receipt"
import { Route as UnauthenticatedOfferPathUriDetailsImport } from "./routes/_unauthenticated/offer/$pathUri/details"
import { Route as ProtectedDashboardSettingsLayoutImport } from "./routes/_protected/dashboard/settings/_layout"
import { Route as ProtectedDashboardServiceContractNewImport } from "./routes/_protected/dashboard/service-contract/new"
import { Route as ProtectedDashboardSalesLayoutImport } from "./routes/_protected/dashboard/sales/_layout"
import { Route as ProtectedDashboardInternalRoutinesNewImport } from "./routes/_protected/dashboard/internal-routines/new"
import { Route as ProtectedDashboardFacilityNewImport } from "./routes/_protected/dashboard/facility/new"
import { Route as ProtectedDashboardEquipmentNewImport } from "./routes/_protected/dashboard/equipment/new"
import { Route as ProtectedDashboardDeviationNewImport } from "./routes/_protected/dashboard/deviation/new"
import { Route as ProtectedDashboardCustomersNewImport } from "./routes/_protected/dashboard/customers/new"
import { Route as ProtectedDashboardChecklistNewImport } from "./routes/_protected/dashboard/checklist/new"
import { Route as ProtectedDashboardActivitiesNewImport } from "./routes/_protected/dashboard/activities/new"
import { Route as ProtectedDashboardTimeNewIndexImport } from "./routes/_protected/dashboard/time/new/index"
import { Route as ProtectedDashboardSalesNewIndexImport } from "./routes/_protected/dashboard/sales/new/index"
import { Route as ProtectedDashboardSalesSalesOpportunityIdIndexImport } from "./routes/_protected/dashboard/sales/$salesOpportunityId/index"
import { Route as ProtectedDashboardProjectNewIndexImport } from "./routes/_protected/dashboard/project/new/index"
import { Route as ProtectedDashboardProductsNewIndexImport } from "./routes/_protected/dashboard/products/new/index"
import { Route as ProtectedDashboardOrdersNewIndexImport } from "./routes/_protected/dashboard/orders/new/index"
import { Route as ProtectedDashboardInvoiceNewIndexImport } from "./routes/_protected/dashboard/invoice/new/index"
import { Route as ProtectedDashboardCustomersCustomerIdIndexImport } from "./routes/_protected/dashboard/customers/$customerId/index"
import { Route as ProtectedDashboardSettingsProjectPhasesEditImport } from "./routes/_protected/dashboard/settings/project-phases/edit"
import { Route as ProtectedDashboardSettingsLayoutVideoGuideImport } from "./routes/_protected/dashboard/settings/_layout/video-guide"
import { Route as ProtectedDashboardSettingsLayoutTaskTemplateImport } from "./routes/_protected/dashboard/settings/_layout/task-template"
import { Route as ProtectedDashboardSettingsLayoutProjectImport } from "./routes/_protected/dashboard/settings/_layout/project"
import { Route as ProtectedDashboardSettingsLayoutOrderImport } from "./routes/_protected/dashboard/settings/_layout/order"
import { Route as ProtectedDashboardSettingsLayoutIntegrationImport } from "./routes/_protected/dashboard/settings/_layout/integration"
import { Route as ProtectedDashboardSettingsLayoutEquipmentImport } from "./routes/_protected/dashboard/settings/_layout/equipment"
import { Route as ProtectedDashboardSettingsLayoutDocumentationTemplateImport } from "./routes/_protected/dashboard/settings/_layout/documentation-template"
import { Route as ProtectedDashboardSettingsLayoutCompanyImport } from "./routes/_protected/dashboard/settings/_layout/company"
import { Route as ProtectedDashboardSettingsLayoutChecklistTemplateImport } from "./routes/_protected/dashboard/settings/_layout/checklist-template"
import { Route as ProtectedDashboardServiceContractServiceContractIdUpdateImport } from "./routes/_protected/dashboard/service-contract/$serviceContractId/update"
import { Route as ProtectedDashboardSalesDirectSaleNewImport } from "./routes/_protected/dashboard/sales/direct-sale/new"
import { Route as ProtectedDashboardSalesLayoutSalesOpportunityImport } from "./routes/_protected/dashboard/sales/_layout/sales-opportunity"
import { Route as ProtectedDashboardSalesLayoutReportImport } from "./routes/_protected/dashboard/sales/_layout/report"
import { Route as ProtectedDashboardSalesLayoutDirectSaleImport } from "./routes/_protected/dashboard/sales/_layout/direct-sale"
import { Route as ProtectedDashboardResourcesEmployeeIdUpdateImport } from "./routes/_protected/dashboard/resources/$employeeId/update"
import { Route as ProtectedDashboardProjectProjectIdReportImport } from "./routes/_protected/dashboard/project/$projectId/report"
import { Route as ProtectedDashboardProjectProjectIdLayoutImport } from "./routes/_protected/dashboard/project/$projectId/_layout"
import { Route as ProtectedDashboardProductsBundlesNewImport } from "./routes/_protected/dashboard/products/bundles/new"
import { Route as ProtectedDashboardProductsProductIdUpdateImport } from "./routes/_protected/dashboard/products/$productId/update"
import { Route as ProtectedDashboardOrdersOrderIdUpdateImport } from "./routes/_protected/dashboard/orders/$orderId/update"
import { Route as ProtectedDashboardOrdersOrderIdReportImport } from "./routes/_protected/dashboard/orders/$orderId/report"
import { Route as ProtectedDashboardOrdersOrderIdLayoutImport } from "./routes/_protected/dashboard/orders/$orderId/_layout"
import { Route as ProtectedDashboardLocationResourceIdUpdateImport } from "./routes/_protected/dashboard/location/$resourceId/update"
import { Route as ProtectedDashboardLocationResourceIdNewImport } from "./routes/_protected/dashboard/location/$resourceId/new"
import { Route as ProtectedDashboardInternalRoutinesRoutineIdUpdateImport } from "./routes/_protected/dashboard/internal-routines/$routineId/update"
import { Route as ProtectedDashboardFacilityFacilityIdUpdateImport } from "./routes/_protected/dashboard/facility/$facilityId/update"
import { Route as ProtectedDashboardFacilityFacilityIdDetailsImport } from "./routes/_protected/dashboard/facility/$facilityId/details"
import { Route as ProtectedDashboardEquipmentEquipmentIdUpdateImport } from "./routes/_protected/dashboard/equipment/$equipmentId/update"
import { Route as ProtectedDashboardEhsTemplateNewImport } from "./routes/_protected/dashboard/ehs/template/new"
import { Route as ProtectedDashboardEhsTemplateIdNewImport } from "./routes/_protected/dashboard/ehs/$templateId/new"
import { Route as ProtectedDashboardEhsEhsIdUpdateImport } from "./routes/_protected/dashboard/ehs/$ehsId/update"
import { Route as ProtectedDashboardDeviationDeviationIdUpdateImport } from "./routes/_protected/dashboard/deviation/$deviationId/update"
import { Route as ProtectedDashboardCustomersCustomerIdEditImport } from "./routes/_protected/dashboard/customers/$customerId/edit"
import { Route as ProtectedDashboardChecklistChecklistIdUpdateImport } from "./routes/_protected/dashboard/checklist/$checklistId/update"
import { Route as ProtectedDashboardActivitiesActivityIdUpdateImport } from "./routes/_protected/dashboard/activities/$activityId/update"
import { Route as ProtectedDashboardProjectProjectIdWorkersIndexImport } from "./routes/_protected/dashboard/project/$projectId/workers/index"
import { Route as ProtectedDashboardProjectProjectIdPhasesIndexImport } from "./routes/_protected/dashboard/project/$projectId/phases/index"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdIndexImport } from "./routes/_protected/dashboard/orders/template/$templateId/index"
import { Route as ProtectedDashboardTimeApprovalUserUserIdImport } from "./routes/_protected/dashboard/time/approval/user/$userId"
import { Route as ProtectedDashboardTimeApprovalProjectProjectIdImport } from "./routes/_protected/dashboard/time/approval/project/$projectId"
import { Route as ProtectedDashboardTimeApprovalOrderOrderIdImport } from "./routes/_protected/dashboard/time/approval/order/$orderId"
import { Route as ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport } from "./routes/_protected/dashboard/sales/offer/$offerId/additional-work"
import { Route as ProtectedDashboardSalesInspectionInspectionIdUpdateImport } from "./routes/_protected/dashboard/sales/inspection/$inspectionId/update"
import { Route as ProtectedDashboardSalesInspectionCustomerIdNewImport } from "./routes/_protected/dashboard/sales/inspection/$customerId/new"
import { Route as ProtectedDashboardSalesSalesOpportunityIdOfferNewImport } from "./routes/_protected/dashboard/sales/$salesOpportunityId/offer/new"
import { Route as ProtectedDashboardSalesSalesOpportunityIdOfferFileNewImport } from "./routes/_protected/dashboard/sales/$salesOpportunityId/offer-file/new"
import { Route as ProtectedDashboardProjectProjectIdTaskTaskIdImport } from "./routes/_protected/dashboard/project/$projectId/task/$taskId"
import { Route as ProtectedDashboardProjectProjectIdProductsAddImport } from "./routes/_protected/dashboard/project/$projectId/products/add"
import { Route as ProtectedDashboardProjectProjectIdLayoutTaskImport } from "./routes/_protected/dashboard/project/$projectId/_layout/task"
import { Route as ProtectedDashboardProjectProjectIdLayoutSubProjectImport } from "./routes/_protected/dashboard/project/$projectId/_layout/sub-project"
import { Route as ProtectedDashboardProjectProjectIdLayoutProgressPlanImport } from "./routes/_protected/dashboard/project/$projectId/_layout/progress-plan"
import { Route as ProtectedDashboardProjectProjectIdLayoutProductImport } from "./routes/_protected/dashboard/project/$projectId/_layout/product"
import { Route as ProtectedDashboardProjectProjectIdLayoutOverviewImport } from "./routes/_protected/dashboard/project/$projectId/_layout/overview"
import { Route as ProtectedDashboardProjectProjectIdLayoutEhsImport } from "./routes/_protected/dashboard/project/$projectId/_layout/ehs"
import { Route as ProtectedDashboardProjectProjectIdLayoutDocumentationImport } from "./routes/_protected/dashboard/project/$projectId/_layout/documentation"
import { Route as ProtectedDashboardProjectProjectIdLayoutDeviationImport } from "./routes/_protected/dashboard/project/$projectId/_layout/deviation"
import { Route as ProtectedDashboardProjectProjectIdLayoutChecklistImport } from "./routes/_protected/dashboard/project/$projectId/_layout/checklist"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout"
import { Route as ProtectedDashboardProductsBundlesBundleIdUpdateImport } from "./routes/_protected/dashboard/products/bundles/$bundleId/update"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdUpdateImport } from "./routes/_protected/dashboard/orders/template/$templateId/update"
import { Route as ProtectedDashboardOrdersTemplateTemplateIdProductImport } from "./routes/_protected/dashboard/orders/template/$templateId/product"
import { Route as ProtectedDashboardOrdersOrderIdProductsAddImport } from "./routes/_protected/dashboard/orders/$orderId/products/add"
import { Route as ProtectedDashboardOrdersOrderIdLayoutTimeImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/time"
import { Route as ProtectedDashboardOrdersOrderIdLayoutProductImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/product"
import { Route as ProtectedDashboardOrdersOrderIdLayoutOverviewImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/overview"
import { Route as ProtectedDashboardOrdersOrderIdLayoutDocumentationImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/documentation"
import { Route as ProtectedDashboardOrdersOrderIdLayoutDeviationImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/deviation"
import { Route as ProtectedDashboardOrdersOrderIdLayoutChecklistImport } from "./routes/_protected/dashboard/orders/$orderId/_layout/checklist"
import { Route as ProtectedDashboardEhsTemplateTemplateIdUpdateImport } from "./routes/_protected/dashboard/ehs/template/$templateId/update"
import { Route as ProtectedDashboardCustomersCustomerIdOrderNewImport } from "./routes/_protected/dashboard/customers/$customerId/order/new"
import { Route as ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateImport } from "./routes/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
import { Route as ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateImport } from "./routes/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/index"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/time"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/subcontractor"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/invoice"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/contract"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/change-order"
import { Route as ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport } from "./routes/_protected/dashboard/project/$projectId/_layout/_layout.economy/budget"

// Create Virtual Routes

const ProtectedDashboardSettingsImport = createFileRoute(
  "/_protected/dashboard/settings",
)()
const ProtectedDashboardSalesImport = createFileRoute(
  "/_protected/dashboard/sales",
)()
const ProtectedDashboardProjectProjectIdImport = createFileRoute(
  "/_protected/dashboard/project/$projectId",
)()
const ProtectedDashboardOrdersOrderIdImport = createFileRoute(
  "/_protected/dashboard/orders/$orderId",
)()

// Create/Update Routes

const UnauthenticatedRoute = UnauthenticatedImport.update({
  id: "/_unauthenticated",
  getParentRoute: () => rootRoute,
} as any)

const ProtectedRoute = ProtectedImport.update({
  id: "/_protected",
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedIndexRoute = UnauthenticatedIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const UnauthenticatedResetPasswordRoute =
  UnauthenticatedResetPasswordImport.update({
    id: "/resetPassword",
    path: "/resetPassword",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const UnauthenticatedLoginRoute = UnauthenticatedLoginImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => UnauthenticatedRoute,
} as any)

const UnauthenticatedCreateAccountRoute =
  UnauthenticatedCreateAccountImport.update({
    id: "/createAccount",
    path: "/createAccount",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const ProtectedWelcomeRoute = ProtectedWelcomeImport.update({
  id: "/welcome",
  path: "/welcome",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedUserInfoRoute = ProtectedUserInfoImport.update({
  id: "/userInfo",
  path: "/userInfo",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedTrialRoute = ProtectedTrialImport.update({
  id: "/trial",
  path: "/trial",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedInvitationsRoute = ProtectedInvitationsImport.update({
  id: "/invitations",
  path: "/invitations",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedDashboardRoute = ProtectedDashboardImport.update({
  id: "/dashboard",
  path: "/dashboard",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedCreateCompanyRoute = ProtectedCreateCompanyImport.update({
  id: "/createCompany",
  path: "/createCompany",
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedDashboardSettingsRoute = ProtectedDashboardSettingsImport.update(
  {
    id: "/settings",
    path: "/settings",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any,
)

const ProtectedDashboardSalesRoute = ProtectedDashboardSalesImport.update({
  id: "/sales",
  path: "/sales",
  getParentRoute: () => ProtectedDashboardRoute,
} as any)

const GuestCustomerIdIndexRoute = GuestCustomerIdIndexImport.update({
  id: "/guest/$customerId/",
  path: "/guest/$customerId/",
  getParentRoute: () => rootRoute,
} as any)

const GuestProjectProjectIdRoute = GuestProjectProjectIdImport.update({
  id: "/guest/project/$projectId",
  path: "/guest/project/$projectId",
  getParentRoute: () => rootRoute,
} as any)

const GuestOrderOrderIdRoute = GuestOrderOrderIdImport.update({
  id: "/guest/order/$orderId",
  path: "/guest/order/$orderId",
  getParentRoute: () => rootRoute,
} as any)

const UnauthenticatedOfferTemplateRoute =
  UnauthenticatedOfferTemplateImport.update({
    id: "/offer/template",
    path: "/offer/template",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const UnauthenticatedOfferPreviewRoute =
  UnauthenticatedOfferPreviewImport.update({
    id: "/offer/preview",
    path: "/offer/preview",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const ProtectedTrialSubscribeRoute = ProtectedTrialSubscribeImport.update({
  id: "/subscribe",
  path: "/subscribe",
  getParentRoute: () => ProtectedTrialRoute,
} as any)

const ProtectedTrialExpiredRoute = ProtectedTrialExpiredImport.update({
  id: "/expired",
  path: "/expired",
  getParentRoute: () => ProtectedTrialRoute,
} as any)

const ProtectedTrialConfirmationRoute = ProtectedTrialConfirmationImport.update(
  {
    id: "/confirmation",
    path: "/confirmation",
    getParentRoute: () => ProtectedTrialRoute,
  } as any,
)

const ProtectedDashboardProjectProjectIdRoute =
  ProtectedDashboardProjectProjectIdImport.update({
    id: "/project/$projectId",
    path: "/project/$projectId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdRoute =
  ProtectedDashboardOrdersOrderIdImport.update({
    id: "/orders/$orderId",
    path: "/orders/$orderId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const UnauthenticatedOfferPathUriIndexRoute =
  UnauthenticatedOfferPathUriIndexImport.update({
    id: "/offer/$pathUri/",
    path: "/offer/$pathUri/",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const ProtectedDashboardTimeIndexRoute =
  ProtectedDashboardTimeIndexImport.update({
    id: "/time/",
    path: "/time/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProjectIndexRoute =
  ProtectedDashboardProjectIndexImport.update({
    id: "/project/",
    path: "/project/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProductsIndexRoute =
  ProtectedDashboardProductsIndexImport.update({
    id: "/products/",
    path: "/products/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardPricesIndexRoute =
  ProtectedDashboardPricesIndexImport.update({
    id: "/prices/",
    path: "/prices/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOverviewIndexRoute =
  ProtectedDashboardOverviewIndexImport.update({
    id: "/overview/",
    path: "/overview/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersIndexRoute =
  ProtectedDashboardOrdersIndexImport.update({
    id: "/orders/",
    path: "/orders/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardInvoiceIndexRoute =
  ProtectedDashboardInvoiceIndexImport.update({
    id: "/invoice/",
    path: "/invoice/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEmployeesIndexRoute =
  ProtectedDashboardEmployeesIndexImport.update({
    id: "/employees/",
    path: "/employees/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsIndexRoute = ProtectedDashboardEhsIndexImport.update(
  {
    id: "/ehs/",
    path: "/ehs/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any,
)

const ProtectedDashboardCustomersIndexRoute =
  ProtectedDashboardCustomersIndexImport.update({
    id: "/customers/",
    path: "/customers/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardActivitiesIndexRoute =
  ProtectedDashboardActivitiesIndexImport.update({
    id: "/activities/",
    path: "/activities/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const UnauthenticatedOfferPathUriReceiptRoute =
  UnauthenticatedOfferPathUriReceiptImport.update({
    id: "/offer/$pathUri/receipt",
    path: "/offer/$pathUri/receipt",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const UnauthenticatedOfferPathUriDetailsRoute =
  UnauthenticatedOfferPathUriDetailsImport.update({
    id: "/offer/$pathUri/details",
    path: "/offer/$pathUri/details",
    getParentRoute: () => UnauthenticatedRoute,
  } as any)

const ProtectedDashboardSettingsLayoutRoute =
  ProtectedDashboardSettingsLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardSettingsRoute,
  } as any)

const ProtectedDashboardServiceContractNewRoute =
  ProtectedDashboardServiceContractNewImport.update({
    id: "/service-contract/new",
    path: "/service-contract/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesLayoutRoute =
  ProtectedDashboardSalesLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardInternalRoutinesNewRoute =
  ProtectedDashboardInternalRoutinesNewImport.update({
    id: "/internal-routines/new",
    path: "/internal-routines/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityNewRoute =
  ProtectedDashboardFacilityNewImport.update({
    id: "/facility/new",
    path: "/facility/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEquipmentNewRoute =
  ProtectedDashboardEquipmentNewImport.update({
    id: "/equipment/new",
    path: "/equipment/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardDeviationNewRoute =
  ProtectedDashboardDeviationNewImport.update({
    id: "/deviation/new",
    path: "/deviation/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersNewRoute =
  ProtectedDashboardCustomersNewImport.update({
    id: "/customers/new",
    path: "/customers/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardChecklistNewRoute =
  ProtectedDashboardChecklistNewImport.update({
    id: "/checklist/new",
    path: "/checklist/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardActivitiesNewRoute =
  ProtectedDashboardActivitiesNewImport.update({
    id: "/activities/new",
    path: "/activities/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardTimeNewIndexRoute =
  ProtectedDashboardTimeNewIndexImport.update({
    id: "/time/new/",
    path: "/time/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesNewIndexRoute =
  ProtectedDashboardSalesNewIndexImport.update({
    id: "/new/",
    path: "/new/",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityIdIndexRoute =
  ProtectedDashboardSalesSalesOpportunityIdIndexImport.update({
    id: "/$salesOpportunityId/",
    path: "/$salesOpportunityId/",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProjectNewIndexRoute =
  ProtectedDashboardProjectNewIndexImport.update({
    id: "/project/new/",
    path: "/project/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProductsNewIndexRoute =
  ProtectedDashboardProductsNewIndexImport.update({
    id: "/products/new/",
    path: "/products/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersNewIndexRoute =
  ProtectedDashboardOrdersNewIndexImport.update({
    id: "/orders/new/",
    path: "/orders/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardInvoiceNewIndexRoute =
  ProtectedDashboardInvoiceNewIndexImport.update({
    id: "/invoice/new/",
    path: "/invoice/new/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdIndexRoute =
  ProtectedDashboardCustomersCustomerIdIndexImport.update({
    id: "/customers/$customerId/",
    path: "/customers/$customerId/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSettingsProjectPhasesEditRoute =
  ProtectedDashboardSettingsProjectPhasesEditImport.update({
    id: "/project-phases/edit",
    path: "/project-phases/edit",
    getParentRoute: () => ProtectedDashboardSettingsRoute,
  } as any)

const ProtectedDashboardSettingsLayoutVideoGuideRoute =
  ProtectedDashboardSettingsLayoutVideoGuideImport.update({
    id: "/video-guide",
    path: "/video-guide",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutTaskTemplateRoute =
  ProtectedDashboardSettingsLayoutTaskTemplateImport.update({
    id: "/task-template",
    path: "/task-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutProjectRoute =
  ProtectedDashboardSettingsLayoutProjectImport.update({
    id: "/project",
    path: "/project",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutOrderRoute =
  ProtectedDashboardSettingsLayoutOrderImport.update({
    id: "/order",
    path: "/order",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutIntegrationRoute =
  ProtectedDashboardSettingsLayoutIntegrationImport.update({
    id: "/integration",
    path: "/integration",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutEquipmentRoute =
  ProtectedDashboardSettingsLayoutEquipmentImport.update({
    id: "/equipment",
    path: "/equipment",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutDocumentationTemplateRoute =
  ProtectedDashboardSettingsLayoutDocumentationTemplateImport.update({
    id: "/documentation-template",
    path: "/documentation-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutCompanyRoute =
  ProtectedDashboardSettingsLayoutCompanyImport.update({
    id: "/company",
    path: "/company",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardSettingsLayoutChecklistTemplateRoute =
  ProtectedDashboardSettingsLayoutChecklistTemplateImport.update({
    id: "/checklist-template",
    path: "/checklist-template",
    getParentRoute: () => ProtectedDashboardSettingsLayoutRoute,
  } as any)

const ProtectedDashboardServiceContractServiceContractIdUpdateRoute =
  ProtectedDashboardServiceContractServiceContractIdUpdateImport.update({
    id: "/service-contract/$serviceContractId/update",
    path: "/service-contract/$serviceContractId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesDirectSaleNewRoute =
  ProtectedDashboardSalesDirectSaleNewImport.update({
    id: "/direct-sale/new",
    path: "/direct-sale/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesLayoutSalesOpportunityRoute =
  ProtectedDashboardSalesLayoutSalesOpportunityImport.update({
    id: "/sales-opportunity",
    path: "/sales-opportunity",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardSalesLayoutReportRoute =
  ProtectedDashboardSalesLayoutReportImport.update({
    id: "/report",
    path: "/report",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardSalesLayoutDirectSaleRoute =
  ProtectedDashboardSalesLayoutDirectSaleImport.update({
    id: "/direct-sale",
    path: "/direct-sale",
    getParentRoute: () => ProtectedDashboardSalesLayoutRoute,
  } as any)

const ProtectedDashboardResourcesEmployeeIdUpdateRoute =
  ProtectedDashboardResourcesEmployeeIdUpdateImport.update({
    id: "/resources/$employeeId/update",
    path: "/resources/$employeeId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProjectProjectIdReportRoute =
  ProtectedDashboardProjectProjectIdReportImport.update({
    id: "/report",
    path: "/report",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutRoute =
  ProtectedDashboardProjectProjectIdLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProductsBundlesNewRoute =
  ProtectedDashboardProductsBundlesNewImport.update({
    id: "/products/bundles/new",
    path: "/products/bundles/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProductsProductIdUpdateRoute =
  ProtectedDashboardProductsProductIdUpdateImport.update({
    id: "/products/$productId/update",
    path: "/products/$productId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdUpdateRoute =
  ProtectedDashboardOrdersOrderIdUpdateImport.update({
    id: "/update",
    path: "/update",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdReportRoute =
  ProtectedDashboardOrdersOrderIdReportImport.update({
    id: "/report",
    path: "/report",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutRoute =
  ProtectedDashboardOrdersOrderIdLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardLocationResourceIdUpdateRoute =
  ProtectedDashboardLocationResourceIdUpdateImport.update({
    id: "/location/$resourceId/update",
    path: "/location/$resourceId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardLocationResourceIdNewRoute =
  ProtectedDashboardLocationResourceIdNewImport.update({
    id: "/location/$resourceId/new",
    path: "/location/$resourceId/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute =
  ProtectedDashboardInternalRoutinesRoutineIdUpdateImport.update({
    id: "/internal-routines/$routineId/update",
    path: "/internal-routines/$routineId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityFacilityIdUpdateRoute =
  ProtectedDashboardFacilityFacilityIdUpdateImport.update({
    id: "/facility/$facilityId/update",
    path: "/facility/$facilityId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardFacilityFacilityIdDetailsRoute =
  ProtectedDashboardFacilityFacilityIdDetailsImport.update({
    id: "/facility/$facilityId/details",
    path: "/facility/$facilityId/details",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEquipmentEquipmentIdUpdateRoute =
  ProtectedDashboardEquipmentEquipmentIdUpdateImport.update({
    id: "/equipment/$equipmentId/update",
    path: "/equipment/$equipmentId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsTemplateNewRoute =
  ProtectedDashboardEhsTemplateNewImport.update({
    id: "/ehs/template/new",
    path: "/ehs/template/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsTemplateIdNewRoute =
  ProtectedDashboardEhsTemplateIdNewImport.update({
    id: "/ehs/$templateId/new",
    path: "/ehs/$templateId/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardEhsEhsIdUpdateRoute =
  ProtectedDashboardEhsEhsIdUpdateImport.update({
    id: "/ehs/$ehsId/update",
    path: "/ehs/$ehsId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardDeviationDeviationIdUpdateRoute =
  ProtectedDashboardDeviationDeviationIdUpdateImport.update({
    id: "/deviation/$deviationId/update",
    path: "/deviation/$deviationId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdEditRoute =
  ProtectedDashboardCustomersCustomerIdEditImport.update({
    id: "/customers/$customerId/edit",
    path: "/customers/$customerId/edit",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardChecklistChecklistIdUpdateRoute =
  ProtectedDashboardChecklistChecklistIdUpdateImport.update({
    id: "/checklist/$checklistId/update",
    path: "/checklist/$checklistId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardActivitiesActivityIdUpdateRoute =
  ProtectedDashboardActivitiesActivityIdUpdateImport.update({
    id: "/activities/$activityId/update",
    path: "/activities/$activityId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardProjectProjectIdWorkersIndexRoute =
  ProtectedDashboardProjectProjectIdWorkersIndexImport.update({
    id: "/workers/",
    path: "/workers/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdPhasesIndexRoute =
  ProtectedDashboardProjectProjectIdPhasesIndexImport.update({
    id: "/phases/",
    path: "/phases/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdIndexRoute =
  ProtectedDashboardOrdersTemplateTemplateIdIndexImport.update({
    id: "/orders/template/$templateId/",
    path: "/orders/template/$templateId/",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardTimeApprovalUserUserIdRoute =
  ProtectedDashboardTimeApprovalUserUserIdImport.update({
    id: "/time/approval/user/$userId",
    path: "/time/approval/user/$userId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardTimeApprovalProjectProjectIdRoute =
  ProtectedDashboardTimeApprovalProjectProjectIdImport.update({
    id: "/time/approval/project/$projectId",
    path: "/time/approval/project/$projectId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardTimeApprovalOrderOrderIdRoute =
  ProtectedDashboardTimeApprovalOrderOrderIdImport.update({
    id: "/time/approval/order/$orderId",
    path: "/time/approval/order/$orderId",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute =
  ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport.update({
    id: "/offer/$offerId/additional-work",
    path: "/offer/$offerId/additional-work",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesInspectionInspectionIdUpdateRoute =
  ProtectedDashboardSalesInspectionInspectionIdUpdateImport.update({
    id: "/inspection/$inspectionId/update",
    path: "/inspection/$inspectionId/update",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesInspectionCustomerIdNewRoute =
  ProtectedDashboardSalesInspectionCustomerIdNewImport.update({
    id: "/inspection/$customerId/new",
    path: "/inspection/$customerId/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute =
  ProtectedDashboardSalesSalesOpportunityIdOfferNewImport.update({
    id: "/$salesOpportunityId/offer/new",
    path: "/$salesOpportunityId/offer/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute =
  ProtectedDashboardSalesSalesOpportunityIdOfferFileNewImport.update({
    id: "/$salesOpportunityId/offer-file/new",
    path: "/$salesOpportunityId/offer-file/new",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProjectProjectIdTaskTaskIdRoute =
  ProtectedDashboardProjectProjectIdTaskTaskIdImport.update({
    id: "/task/$taskId",
    path: "/task/$taskId",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdProductsAddRoute =
  ProtectedDashboardProjectProjectIdProductsAddImport.update({
    id: "/products/add",
    path: "/products/add",
    getParentRoute: () => ProtectedDashboardProjectProjectIdRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutTaskRoute =
  ProtectedDashboardProjectProjectIdLayoutTaskImport.update({
    id: "/task",
    path: "/task",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutSubProjectRoute =
  ProtectedDashboardProjectProjectIdLayoutSubProjectImport.update({
    id: "/sub-project",
    path: "/sub-project",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute =
  ProtectedDashboardProjectProjectIdLayoutProgressPlanImport.update({
    id: "/progress-plan",
    path: "/progress-plan",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutProductRoute =
  ProtectedDashboardProjectProjectIdLayoutProductImport.update({
    id: "/product",
    path: "/product",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutOverviewRoute =
  ProtectedDashboardProjectProjectIdLayoutOverviewImport.update({
    id: "/overview",
    path: "/overview",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutEhsRoute =
  ProtectedDashboardProjectProjectIdLayoutEhsImport.update({
    id: "/ehs",
    path: "/ehs",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutDocumentationRoute =
  ProtectedDashboardProjectProjectIdLayoutDocumentationImport.update({
    id: "/documentation",
    path: "/documentation",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutDeviationRoute =
  ProtectedDashboardProjectProjectIdLayoutDeviationImport.update({
    id: "/deviation",
    path: "/deviation",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutChecklistRoute =
  ProtectedDashboardProjectProjectIdLayoutChecklistImport.update({
    id: "/checklist",
    path: "/checklist",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutImport.update({
    id: "/_layout",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutRoute,
  } as any)

const ProtectedDashboardProductsBundlesBundleIdUpdateRoute =
  ProtectedDashboardProductsBundlesBundleIdUpdateImport.update({
    id: "/products/bundles/$bundleId/update",
    path: "/products/bundles/$bundleId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute =
  ProtectedDashboardOrdersTemplateTemplateIdUpdateImport.update({
    id: "/orders/template/$templateId/update",
    path: "/orders/template/$templateId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersTemplateTemplateIdProductRoute =
  ProtectedDashboardOrdersTemplateTemplateIdProductImport.update({
    id: "/orders/template/$templateId/product",
    path: "/orders/template/$templateId/product",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdProductsAddRoute =
  ProtectedDashboardOrdersOrderIdProductsAddImport.update({
    id: "/products/add",
    path: "/products/add",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutTimeRoute =
  ProtectedDashboardOrdersOrderIdLayoutTimeImport.update({
    id: "/time",
    path: "/time",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutProductRoute =
  ProtectedDashboardOrdersOrderIdLayoutProductImport.update({
    id: "/product",
    path: "/product",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutOverviewRoute =
  ProtectedDashboardOrdersOrderIdLayoutOverviewImport.update({
    id: "/overview",
    path: "/overview",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute =
  ProtectedDashboardOrdersOrderIdLayoutDocumentationImport.update({
    id: "/documentation",
    path: "/documentation",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutDeviationRoute =
  ProtectedDashboardOrdersOrderIdLayoutDeviationImport.update({
    id: "/deviation",
    path: "/deviation",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardOrdersOrderIdLayoutChecklistRoute =
  ProtectedDashboardOrdersOrderIdLayoutChecklistImport.update({
    id: "/checklist",
    path: "/checklist",
    getParentRoute: () => ProtectedDashboardOrdersOrderIdLayoutRoute,
  } as any)

const ProtectedDashboardEhsTemplateTemplateIdUpdateRoute =
  ProtectedDashboardEhsTemplateTemplateIdUpdateImport.update({
    id: "/ehs/template/$templateId/update",
    path: "/ehs/template/$templateId/update",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardCustomersCustomerIdOrderNewRoute =
  ProtectedDashboardCustomersCustomerIdOrderNewImport.update({
    id: "/customers/$customerId/order/new",
    path: "/customers/$customerId/order/new",
    getParentRoute: () => ProtectedDashboardRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute =
  ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateImport.update({
    id: "/$salesOpportunityId/offer/$offerId/update",
    path: "/$salesOpportunityId/offer/$offerId/update",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute =
  ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateImport.update({
    id: "/$salesOpportunityId/offer-file/$offerId/update",
    path: "/$salesOpportunityId/offer-file/$offerId/update",
    getParentRoute: () => ProtectedDashboardSalesRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport.update({
    id: "/economy/",
    path: "/economy/",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport.update({
    id: "/economy/time",
    path: "/economy/time",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport.update(
    {
      id: "/economy/subcontractor",
      path: "/economy/subcontractor",
      getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport.update({
    id: "/economy/invoice",
    path: "/economy/invoice",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport.update({
    id: "/economy/contract",
    path: "/economy/contract",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport.update(
    {
      id: "/economy/change-order",
      path: "/economy/change-order",
      getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
    } as any,
  )

const ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute =
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport.update({
    id: "/economy/budget",
    path: "/economy/budget",
    getParentRoute: () => ProtectedDashboardProjectProjectIdLayoutLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/_protected": {
      id: "/_protected"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    "/_unauthenticated": {
      id: "/_unauthenticated"
      path: ""
      fullPath: ""
      preLoaderRoute: typeof UnauthenticatedImport
      parentRoute: typeof rootRoute
    }
    "/_protected/createCompany": {
      id: "/_protected/createCompany"
      path: "/createCompany"
      fullPath: "/createCompany"
      preLoaderRoute: typeof ProtectedCreateCompanyImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/dashboard": {
      id: "/_protected/dashboard"
      path: "/dashboard"
      fullPath: "/dashboard"
      preLoaderRoute: typeof ProtectedDashboardImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/invitations": {
      id: "/_protected/invitations"
      path: "/invitations"
      fullPath: "/invitations"
      preLoaderRoute: typeof ProtectedInvitationsImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/trial": {
      id: "/_protected/trial"
      path: "/trial"
      fullPath: "/trial"
      preLoaderRoute: typeof ProtectedTrialImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/userInfo": {
      id: "/_protected/userInfo"
      path: "/userInfo"
      fullPath: "/userInfo"
      preLoaderRoute: typeof ProtectedUserInfoImport
      parentRoute: typeof ProtectedImport
    }
    "/_protected/welcome": {
      id: "/_protected/welcome"
      path: "/welcome"
      fullPath: "/welcome"
      preLoaderRoute: typeof ProtectedWelcomeImport
      parentRoute: typeof ProtectedImport
    }
    "/_unauthenticated/createAccount": {
      id: "/_unauthenticated/createAccount"
      path: "/createAccount"
      fullPath: "/createAccount"
      preLoaderRoute: typeof UnauthenticatedCreateAccountImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/login": {
      id: "/_unauthenticated/login"
      path: "/login"
      fullPath: "/login"
      preLoaderRoute: typeof UnauthenticatedLoginImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/resetPassword": {
      id: "/_unauthenticated/resetPassword"
      path: "/resetPassword"
      fullPath: "/resetPassword"
      preLoaderRoute: typeof UnauthenticatedResetPasswordImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/": {
      id: "/_unauthenticated/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof UnauthenticatedIndexImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_protected/trial/confirmation": {
      id: "/_protected/trial/confirmation"
      path: "/confirmation"
      fullPath: "/trial/confirmation"
      preLoaderRoute: typeof ProtectedTrialConfirmationImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/_protected/trial/expired": {
      id: "/_protected/trial/expired"
      path: "/expired"
      fullPath: "/trial/expired"
      preLoaderRoute: typeof ProtectedTrialExpiredImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/_protected/trial/subscribe": {
      id: "/_protected/trial/subscribe"
      path: "/subscribe"
      fullPath: "/trial/subscribe"
      preLoaderRoute: typeof ProtectedTrialSubscribeImport
      parentRoute: typeof ProtectedTrialImport
    }
    "/_unauthenticated/offer/preview": {
      id: "/_unauthenticated/offer/preview"
      path: "/offer/preview"
      fullPath: "/offer/preview"
      preLoaderRoute: typeof UnauthenticatedOfferPreviewImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/offer/template": {
      id: "/_unauthenticated/offer/template"
      path: "/offer/template"
      fullPath: "/offer/template"
      preLoaderRoute: typeof UnauthenticatedOfferTemplateImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/guest/order/$orderId": {
      id: "/guest/order/$orderId"
      path: "/guest/order/$orderId"
      fullPath: "/guest/order/$orderId"
      preLoaderRoute: typeof GuestOrderOrderIdImport
      parentRoute: typeof rootRoute
    }
    "/guest/project/$projectId": {
      id: "/guest/project/$projectId"
      path: "/guest/project/$projectId"
      fullPath: "/guest/project/$projectId"
      preLoaderRoute: typeof GuestProjectProjectIdImport
      parentRoute: typeof rootRoute
    }
    "/guest/$customerId/": {
      id: "/guest/$customerId/"
      path: "/guest/$customerId"
      fullPath: "/guest/$customerId"
      preLoaderRoute: typeof GuestCustomerIdIndexImport
      parentRoute: typeof rootRoute
    }
    "/_protected/dashboard/activities/new": {
      id: "/_protected/dashboard/activities/new"
      path: "/activities/new"
      fullPath: "/dashboard/activities/new"
      preLoaderRoute: typeof ProtectedDashboardActivitiesNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/checklist/new": {
      id: "/_protected/dashboard/checklist/new"
      path: "/checklist/new"
      fullPath: "/dashboard/checklist/new"
      preLoaderRoute: typeof ProtectedDashboardChecklistNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/new": {
      id: "/_protected/dashboard/customers/new"
      path: "/customers/new"
      fullPath: "/dashboard/customers/new"
      preLoaderRoute: typeof ProtectedDashboardCustomersNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/deviation/new": {
      id: "/_protected/dashboard/deviation/new"
      path: "/deviation/new"
      fullPath: "/dashboard/deviation/new"
      preLoaderRoute: typeof ProtectedDashboardDeviationNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/equipment/new": {
      id: "/_protected/dashboard/equipment/new"
      path: "/equipment/new"
      fullPath: "/dashboard/equipment/new"
      preLoaderRoute: typeof ProtectedDashboardEquipmentNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/new": {
      id: "/_protected/dashboard/facility/new"
      path: "/facility/new"
      fullPath: "/dashboard/facility/new"
      preLoaderRoute: typeof ProtectedDashboardFacilityNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/internal-routines/new": {
      id: "/_protected/dashboard/internal-routines/new"
      path: "/internal-routines/new"
      fullPath: "/dashboard/internal-routines/new"
      preLoaderRoute: typeof ProtectedDashboardInternalRoutinesNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales": {
      id: "/_protected/dashboard/sales"
      path: "/sales"
      fullPath: "/dashboard/sales"
      preLoaderRoute: typeof ProtectedDashboardSalesImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/_layout": {
      id: "/_protected/dashboard/sales/_layout"
      path: "/sales"
      fullPath: "/dashboard/sales"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutImport
      parentRoute: typeof ProtectedDashboardSalesRoute
    }
    "/_protected/dashboard/service-contract/new": {
      id: "/_protected/dashboard/service-contract/new"
      path: "/service-contract/new"
      fullPath: "/dashboard/service-contract/new"
      preLoaderRoute: typeof ProtectedDashboardServiceContractNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings": {
      id: "/_protected/dashboard/settings"
      path: "/settings"
      fullPath: "/dashboard/settings"
      preLoaderRoute: typeof ProtectedDashboardSettingsImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings/_layout": {
      id: "/_protected/dashboard/settings/_layout"
      path: "/settings"
      fullPath: "/dashboard/settings"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutImport
      parentRoute: typeof ProtectedDashboardSettingsRoute
    }
    "/_unauthenticated/offer/$pathUri/details": {
      id: "/_unauthenticated/offer/$pathUri/details"
      path: "/offer/$pathUri/details"
      fullPath: "/offer/$pathUri/details"
      preLoaderRoute: typeof UnauthenticatedOfferPathUriDetailsImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_unauthenticated/offer/$pathUri/receipt": {
      id: "/_unauthenticated/offer/$pathUri/receipt"
      path: "/offer/$pathUri/receipt"
      fullPath: "/offer/$pathUri/receipt"
      preLoaderRoute: typeof UnauthenticatedOfferPathUriReceiptImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_protected/dashboard/activities/": {
      id: "/_protected/dashboard/activities/"
      path: "/activities"
      fullPath: "/dashboard/activities"
      preLoaderRoute: typeof ProtectedDashboardActivitiesIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/": {
      id: "/_protected/dashboard/customers/"
      path: "/customers"
      fullPath: "/dashboard/customers"
      preLoaderRoute: typeof ProtectedDashboardCustomersIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/": {
      id: "/_protected/dashboard/ehs/"
      path: "/ehs"
      fullPath: "/dashboard/ehs"
      preLoaderRoute: typeof ProtectedDashboardEhsIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/employees/": {
      id: "/_protected/dashboard/employees/"
      path: "/employees"
      fullPath: "/dashboard/employees"
      preLoaderRoute: typeof ProtectedDashboardEmployeesIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/invoice/": {
      id: "/_protected/dashboard/invoice/"
      path: "/invoice"
      fullPath: "/dashboard/invoice"
      preLoaderRoute: typeof ProtectedDashboardInvoiceIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/": {
      id: "/_protected/dashboard/orders/"
      path: "/orders"
      fullPath: "/dashboard/orders"
      preLoaderRoute: typeof ProtectedDashboardOrdersIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/overview/": {
      id: "/_protected/dashboard/overview/"
      path: "/overview"
      fullPath: "/dashboard/overview"
      preLoaderRoute: typeof ProtectedDashboardOverviewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/prices/": {
      id: "/_protected/dashboard/prices/"
      path: "/prices"
      fullPath: "/dashboard/prices"
      preLoaderRoute: typeof ProtectedDashboardPricesIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/": {
      id: "/_protected/dashboard/products/"
      path: "/products"
      fullPath: "/dashboard/products"
      preLoaderRoute: typeof ProtectedDashboardProductsIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/": {
      id: "/_protected/dashboard/project/"
      path: "/project"
      fullPath: "/dashboard/project"
      preLoaderRoute: typeof ProtectedDashboardProjectIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/time/": {
      id: "/_protected/dashboard/time/"
      path: "/time"
      fullPath: "/dashboard/time"
      preLoaderRoute: typeof ProtectedDashboardTimeIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_unauthenticated/offer/$pathUri/": {
      id: "/_unauthenticated/offer/$pathUri/"
      path: "/offer/$pathUri"
      fullPath: "/offer/$pathUri"
      preLoaderRoute: typeof UnauthenticatedOfferPathUriIndexImport
      parentRoute: typeof UnauthenticatedImport
    }
    "/_protected/dashboard/activities/$activityId/update": {
      id: "/_protected/dashboard/activities/$activityId/update"
      path: "/activities/$activityId/update"
      fullPath: "/dashboard/activities/$activityId/update"
      preLoaderRoute: typeof ProtectedDashboardActivitiesActivityIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/checklist/$checklistId/update": {
      id: "/_protected/dashboard/checklist/$checklistId/update"
      path: "/checklist/$checklistId/update"
      fullPath: "/dashboard/checklist/$checklistId/update"
      preLoaderRoute: typeof ProtectedDashboardChecklistChecklistIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/$customerId/edit": {
      id: "/_protected/dashboard/customers/$customerId/edit"
      path: "/customers/$customerId/edit"
      fullPath: "/dashboard/customers/$customerId/edit"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdEditImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/deviation/$deviationId/update": {
      id: "/_protected/dashboard/deviation/$deviationId/update"
      path: "/deviation/$deviationId/update"
      fullPath: "/dashboard/deviation/$deviationId/update"
      preLoaderRoute: typeof ProtectedDashboardDeviationDeviationIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/$ehsId/update": {
      id: "/_protected/dashboard/ehs/$ehsId/update"
      path: "/ehs/$ehsId/update"
      fullPath: "/dashboard/ehs/$ehsId/update"
      preLoaderRoute: typeof ProtectedDashboardEhsEhsIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/$templateId/new": {
      id: "/_protected/dashboard/ehs/$templateId/new"
      path: "/ehs/$templateId/new"
      fullPath: "/dashboard/ehs/$templateId/new"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateIdNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/template/new": {
      id: "/_protected/dashboard/ehs/template/new"
      path: "/ehs/template/new"
      fullPath: "/dashboard/ehs/template/new"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/equipment/$equipmentId/update": {
      id: "/_protected/dashboard/equipment/$equipmentId/update"
      path: "/equipment/$equipmentId/update"
      fullPath: "/dashboard/equipment/$equipmentId/update"
      preLoaderRoute: typeof ProtectedDashboardEquipmentEquipmentIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/$facilityId/details": {
      id: "/_protected/dashboard/facility/$facilityId/details"
      path: "/facility/$facilityId/details"
      fullPath: "/dashboard/facility/$facilityId/details"
      preLoaderRoute: typeof ProtectedDashboardFacilityFacilityIdDetailsImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/facility/$facilityId/update": {
      id: "/_protected/dashboard/facility/$facilityId/update"
      path: "/facility/$facilityId/update"
      fullPath: "/dashboard/facility/$facilityId/update"
      preLoaderRoute: typeof ProtectedDashboardFacilityFacilityIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/internal-routines/$routineId/update": {
      id: "/_protected/dashboard/internal-routines/$routineId/update"
      path: "/internal-routines/$routineId/update"
      fullPath: "/dashboard/internal-routines/$routineId/update"
      preLoaderRoute: typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/location/$resourceId/new": {
      id: "/_protected/dashboard/location/$resourceId/new"
      path: "/location/$resourceId/new"
      fullPath: "/dashboard/location/$resourceId/new"
      preLoaderRoute: typeof ProtectedDashboardLocationResourceIdNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/location/$resourceId/update": {
      id: "/_protected/dashboard/location/$resourceId/update"
      path: "/location/$resourceId/update"
      fullPath: "/dashboard/location/$resourceId/update"
      preLoaderRoute: typeof ProtectedDashboardLocationResourceIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId": {
      id: "/_protected/dashboard/orders/$orderId"
      path: "/orders/$orderId"
      fullPath: "/dashboard/orders/$orderId"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId/_layout": {
      id: "/_protected/dashboard/orders/$orderId/_layout"
      path: "/orders/$orderId"
      fullPath: "/dashboard/orders/$orderId"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdRoute
    }
    "/_protected/dashboard/orders/$orderId/report": {
      id: "/_protected/dashboard/orders/$orderId/report"
      path: "/report"
      fullPath: "/dashboard/orders/$orderId/report"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdReportImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdImport
    }
    "/_protected/dashboard/orders/$orderId/update": {
      id: "/_protected/dashboard/orders/$orderId/update"
      path: "/update"
      fullPath: "/dashboard/orders/$orderId/update"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdUpdateImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdImport
    }
    "/_protected/dashboard/products/$productId/update": {
      id: "/_protected/dashboard/products/$productId/update"
      path: "/products/$productId/update"
      fullPath: "/dashboard/products/$productId/update"
      preLoaderRoute: typeof ProtectedDashboardProductsProductIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/bundles/new": {
      id: "/_protected/dashboard/products/bundles/new"
      path: "/products/bundles/new"
      fullPath: "/dashboard/products/bundles/new"
      preLoaderRoute: typeof ProtectedDashboardProductsBundlesNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId": {
      id: "/_protected/dashboard/project/$projectId"
      path: "/project/$projectId"
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId/_layout": {
      id: "/_protected/dashboard/project/$projectId/_layout"
      path: "/project/$projectId"
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdRoute
    }
    "/_protected/dashboard/project/$projectId/report": {
      id: "/_protected/dashboard/project/$projectId/report"
      path: "/report"
      fullPath: "/dashboard/project/$projectId/report"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdReportImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/resources/$employeeId/update": {
      id: "/_protected/dashboard/resources/$employeeId/update"
      path: "/resources/$employeeId/update"
      fullPath: "/dashboard/resources/$employeeId/update"
      preLoaderRoute: typeof ProtectedDashboardResourcesEmployeeIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/_layout/direct-sale": {
      id: "/_protected/dashboard/sales/_layout/direct-sale"
      path: "/direct-sale"
      fullPath: "/dashboard/sales/direct-sale"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutDirectSaleImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/_layout/report": {
      id: "/_protected/dashboard/sales/_layout/report"
      path: "/report"
      fullPath: "/dashboard/sales/report"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutReportImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/_layout/sales-opportunity": {
      id: "/_protected/dashboard/sales/_layout/sales-opportunity"
      path: "/sales-opportunity"
      fullPath: "/dashboard/sales/sales-opportunity"
      preLoaderRoute: typeof ProtectedDashboardSalesLayoutSalesOpportunityImport
      parentRoute: typeof ProtectedDashboardSalesLayoutImport
    }
    "/_protected/dashboard/sales/direct-sale/new": {
      id: "/_protected/dashboard/sales/direct-sale/new"
      path: "/direct-sale/new"
      fullPath: "/dashboard/sales/direct-sale/new"
      preLoaderRoute: typeof ProtectedDashboardSalesDirectSaleNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/service-contract/$serviceContractId/update": {
      id: "/_protected/dashboard/service-contract/$serviceContractId/update"
      path: "/service-contract/$serviceContractId/update"
      fullPath: "/dashboard/service-contract/$serviceContractId/update"
      preLoaderRoute: typeof ProtectedDashboardServiceContractServiceContractIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/settings/_layout/checklist-template": {
      id: "/_protected/dashboard/settings/_layout/checklist-template"
      path: "/checklist-template"
      fullPath: "/dashboard/settings/checklist-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutChecklistTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/company": {
      id: "/_protected/dashboard/settings/_layout/company"
      path: "/company"
      fullPath: "/dashboard/settings/company"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutCompanyImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/documentation-template": {
      id: "/_protected/dashboard/settings/_layout/documentation-template"
      path: "/documentation-template"
      fullPath: "/dashboard/settings/documentation-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutDocumentationTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/equipment": {
      id: "/_protected/dashboard/settings/_layout/equipment"
      path: "/equipment"
      fullPath: "/dashboard/settings/equipment"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutEquipmentImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/integration": {
      id: "/_protected/dashboard/settings/_layout/integration"
      path: "/integration"
      fullPath: "/dashboard/settings/integration"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutIntegrationImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/order": {
      id: "/_protected/dashboard/settings/_layout/order"
      path: "/order"
      fullPath: "/dashboard/settings/order"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutOrderImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/project": {
      id: "/_protected/dashboard/settings/_layout/project"
      path: "/project"
      fullPath: "/dashboard/settings/project"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutProjectImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/task-template": {
      id: "/_protected/dashboard/settings/_layout/task-template"
      path: "/task-template"
      fullPath: "/dashboard/settings/task-template"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutTaskTemplateImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/_layout/video-guide": {
      id: "/_protected/dashboard/settings/_layout/video-guide"
      path: "/video-guide"
      fullPath: "/dashboard/settings/video-guide"
      preLoaderRoute: typeof ProtectedDashboardSettingsLayoutVideoGuideImport
      parentRoute: typeof ProtectedDashboardSettingsLayoutImport
    }
    "/_protected/dashboard/settings/project-phases/edit": {
      id: "/_protected/dashboard/settings/project-phases/edit"
      path: "/project-phases/edit"
      fullPath: "/dashboard/settings/project-phases/edit"
      preLoaderRoute: typeof ProtectedDashboardSettingsProjectPhasesEditImport
      parentRoute: typeof ProtectedDashboardSettingsImport
    }
    "/_protected/dashboard/customers/$customerId/": {
      id: "/_protected/dashboard/customers/$customerId/"
      path: "/customers/$customerId"
      fullPath: "/dashboard/customers/$customerId"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/invoice/new/": {
      id: "/_protected/dashboard/invoice/new/"
      path: "/invoice/new"
      fullPath: "/dashboard/invoice/new"
      preLoaderRoute: typeof ProtectedDashboardInvoiceNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/new/": {
      id: "/_protected/dashboard/orders/new/"
      path: "/orders/new"
      fullPath: "/dashboard/orders/new"
      preLoaderRoute: typeof ProtectedDashboardOrdersNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/new/": {
      id: "/_protected/dashboard/products/new/"
      path: "/products/new"
      fullPath: "/dashboard/products/new"
      preLoaderRoute: typeof ProtectedDashboardProductsNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/new/": {
      id: "/_protected/dashboard/project/new/"
      path: "/project/new"
      fullPath: "/dashboard/project/new"
      preLoaderRoute: typeof ProtectedDashboardProjectNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/sales/$salesOpportunityId/": {
      id: "/_protected/dashboard/sales/$salesOpportunityId/"
      path: "/$salesOpportunityId"
      fullPath: "/dashboard/sales/$salesOpportunityId"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityIdIndexImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/new/": {
      id: "/_protected/dashboard/sales/new/"
      path: "/new"
      fullPath: "/dashboard/sales/new"
      preLoaderRoute: typeof ProtectedDashboardSalesNewIndexImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/time/new/": {
      id: "/_protected/dashboard/time/new/"
      path: "/time/new"
      fullPath: "/dashboard/time/new"
      preLoaderRoute: typeof ProtectedDashboardTimeNewIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/customers/$customerId/order/new": {
      id: "/_protected/dashboard/customers/$customerId/order/new"
      path: "/customers/$customerId/order/new"
      fullPath: "/dashboard/customers/$customerId/order/new"
      preLoaderRoute: typeof ProtectedDashboardCustomersCustomerIdOrderNewImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/ehs/template/$templateId/update": {
      id: "/_protected/dashboard/ehs/template/$templateId/update"
      path: "/ehs/template/$templateId/update"
      fullPath: "/dashboard/ehs/template/$templateId/update"
      preLoaderRoute: typeof ProtectedDashboardEhsTemplateTemplateIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/checklist": {
      id: "/_protected/dashboard/orders/$orderId/_layout/checklist"
      path: "/checklist"
      fullPath: "/dashboard/orders/$orderId/checklist"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutChecklistImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/deviation": {
      id: "/_protected/dashboard/orders/$orderId/_layout/deviation"
      path: "/deviation"
      fullPath: "/dashboard/orders/$orderId/deviation"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDeviationImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/documentation": {
      id: "/_protected/dashboard/orders/$orderId/_layout/documentation"
      path: "/documentation"
      fullPath: "/dashboard/orders/$orderId/documentation"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/overview": {
      id: "/_protected/dashboard/orders/$orderId/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/orders/$orderId/overview"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/product": {
      id: "/_protected/dashboard/orders/$orderId/_layout/product"
      path: "/product"
      fullPath: "/dashboard/orders/$orderId/product"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutProductImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/_layout/time": {
      id: "/_protected/dashboard/orders/$orderId/_layout/time"
      path: "/time"
      fullPath: "/dashboard/orders/$orderId/time"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdLayoutTimeImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdLayoutImport
    }
    "/_protected/dashboard/orders/$orderId/products/add": {
      id: "/_protected/dashboard/orders/$orderId/products/add"
      path: "/products/add"
      fullPath: "/dashboard/orders/$orderId/products/add"
      preLoaderRoute: typeof ProtectedDashboardOrdersOrderIdProductsAddImport
      parentRoute: typeof ProtectedDashboardOrdersOrderIdImport
    }
    "/_protected/dashboard/orders/template/$templateId/product": {
      id: "/_protected/dashboard/orders/template/$templateId/product"
      path: "/orders/template/$templateId/product"
      fullPath: "/dashboard/orders/template/$templateId/product"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdProductImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/template/$templateId/update": {
      id: "/_protected/dashboard/orders/template/$templateId/update"
      path: "/orders/template/$templateId/update"
      fullPath: "/dashboard/orders/template/$templateId/update"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/products/bundles/$bundleId/update": {
      id: "/_protected/dashboard/products/bundles/$bundleId/update"
      path: "/products/bundles/$bundleId/update"
      fullPath: "/dashboard/products/bundles/$bundleId/update"
      preLoaderRoute: typeof ProtectedDashboardProductsBundlesBundleIdUpdateImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout"
      path: ""
      fullPath: "/dashboard/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/checklist": {
      id: "/_protected/dashboard/project/$projectId/_layout/checklist"
      path: "/checklist"
      fullPath: "/dashboard/project/$projectId/checklist"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutChecklistImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/deviation": {
      id: "/_protected/dashboard/project/$projectId/_layout/deviation"
      path: "/deviation"
      fullPath: "/dashboard/project/$projectId/deviation"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutDeviationImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/documentation": {
      id: "/_protected/dashboard/project/$projectId/_layout/documentation"
      path: "/documentation"
      fullPath: "/dashboard/project/$projectId/documentation"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutDocumentationImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/ehs": {
      id: "/_protected/dashboard/project/$projectId/_layout/ehs"
      path: "/ehs"
      fullPath: "/dashboard/project/$projectId/ehs"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutEhsImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/overview": {
      id: "/_protected/dashboard/project/$projectId/_layout/overview"
      path: "/overview"
      fullPath: "/dashboard/project/$projectId/overview"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/product": {
      id: "/_protected/dashboard/project/$projectId/_layout/product"
      path: "/product"
      fullPath: "/dashboard/project/$projectId/product"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutProductImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/progress-plan": {
      id: "/_protected/dashboard/project/$projectId/_layout/progress-plan"
      path: "/progress-plan"
      fullPath: "/dashboard/project/$projectId/progress-plan"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/sub-project": {
      id: "/_protected/dashboard/project/$projectId/_layout/sub-project"
      path: "/sub-project"
      fullPath: "/dashboard/project/$projectId/sub-project"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutSubProjectImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/task": {
      id: "/_protected/dashboard/project/$projectId/_layout/task"
      path: "/task"
      fullPath: "/dashboard/project/$projectId/task"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutTaskImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutImport
    }
    "/_protected/dashboard/project/$projectId/products/add": {
      id: "/_protected/dashboard/project/$projectId/products/add"
      path: "/products/add"
      fullPath: "/dashboard/project/$projectId/products/add"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdProductsAddImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/$projectId/task/$taskId": {
      id: "/_protected/dashboard/project/$projectId/task/$taskId"
      path: "/task/$taskId"
      fullPath: "/dashboard/project/$projectId/task/$taskId"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdTaskTaskIdImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new": {
      id: "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new"
      path: "/$salesOpportunityId/offer-file/new"
      fullPath: "/dashboard/sales/$salesOpportunityId/offer-file/new"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/$salesOpportunityId/offer/new": {
      id: "/_protected/dashboard/sales/$salesOpportunityId/offer/new"
      path: "/$salesOpportunityId/offer/new"
      fullPath: "/dashboard/sales/$salesOpportunityId/offer/new"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/inspection/$customerId/new": {
      id: "/_protected/dashboard/sales/inspection/$customerId/new"
      path: "/inspection/$customerId/new"
      fullPath: "/dashboard/sales/inspection/$customerId/new"
      preLoaderRoute: typeof ProtectedDashboardSalesInspectionCustomerIdNewImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/inspection/$inspectionId/update": {
      id: "/_protected/dashboard/sales/inspection/$inspectionId/update"
      path: "/inspection/$inspectionId/update"
      fullPath: "/dashboard/sales/inspection/$inspectionId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesInspectionInspectionIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/offer/$offerId/additional-work": {
      id: "/_protected/dashboard/sales/offer/$offerId/additional-work"
      path: "/offer/$offerId/additional-work"
      fullPath: "/dashboard/sales/offer/$offerId/additional-work"
      preLoaderRoute: typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/time/approval/order/$orderId": {
      id: "/_protected/dashboard/time/approval/order/$orderId"
      path: "/time/approval/order/$orderId"
      fullPath: "/dashboard/time/approval/order/$orderId"
      preLoaderRoute: typeof ProtectedDashboardTimeApprovalOrderOrderIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/time/approval/project/$projectId": {
      id: "/_protected/dashboard/time/approval/project/$projectId"
      path: "/time/approval/project/$projectId"
      fullPath: "/dashboard/time/approval/project/$projectId"
      preLoaderRoute: typeof ProtectedDashboardTimeApprovalProjectProjectIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/time/approval/user/$userId": {
      id: "/_protected/dashboard/time/approval/user/$userId"
      path: "/time/approval/user/$userId"
      fullPath: "/dashboard/time/approval/user/$userId"
      preLoaderRoute: typeof ProtectedDashboardTimeApprovalUserUserIdImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/orders/template/$templateId/": {
      id: "/_protected/dashboard/orders/template/$templateId/"
      path: "/orders/template/$templateId"
      fullPath: "/dashboard/orders/template/$templateId"
      preLoaderRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdIndexImport
      parentRoute: typeof ProtectedDashboardImport
    }
    "/_protected/dashboard/project/$projectId/phases/": {
      id: "/_protected/dashboard/project/$projectId/phases/"
      path: "/phases"
      fullPath: "/dashboard/project/$projectId/phases"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdPhasesIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/project/$projectId/workers/": {
      id: "/_protected/dashboard/project/$projectId/workers/"
      path: "/workers"
      fullPath: "/dashboard/project/$projectId/workers"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdWorkersIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdImport
    }
    "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update": {
      id: "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
      path: "/$salesOpportunityId/offer-file/$offerId/update"
      fullPath: "/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update": {
      id: "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
      path: "/$salesOpportunityId/offer/$offerId/update"
      fullPath: "/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
      preLoaderRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateImport
      parentRoute: typeof ProtectedDashboardSalesImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget"
      path: "/economy/budget"
      fullPath: "/dashboard/project/$projectId/economy/budget"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order"
      path: "/economy/change-order"
      fullPath: "/dashboard/project/$projectId/economy/change-order"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract"
      path: "/economy/contract"
      fullPath: "/dashboard/project/$projectId/economy/contract"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
      path: "/economy/invoice"
      fullPath: "/dashboard/project/$projectId/economy/invoice"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor"
      path: "/economy/subcontractor"
      fullPath: "/dashboard/project/$projectId/economy/subcontractor"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time"
      path: "/economy/time"
      fullPath: "/dashboard/project/$projectId/economy/time"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": {
      id: "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
      path: "/economy"
      fullPath: "/dashboard/project/$projectId/economy"
      preLoaderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexImport
      parentRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutImport
    }
  }
}

// Create and export the route tree

interface ProtectedDashboardSalesLayoutRouteChildren {
  ProtectedDashboardSalesLayoutDirectSaleRoute: typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  ProtectedDashboardSalesLayoutReportRoute: typeof ProtectedDashboardSalesLayoutReportRoute
  ProtectedDashboardSalesLayoutSalesOpportunityRoute: typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
}

const ProtectedDashboardSalesLayoutRouteChildren: ProtectedDashboardSalesLayoutRouteChildren =
  {
    ProtectedDashboardSalesLayoutDirectSaleRoute:
      ProtectedDashboardSalesLayoutDirectSaleRoute,
    ProtectedDashboardSalesLayoutReportRoute:
      ProtectedDashboardSalesLayoutReportRoute,
    ProtectedDashboardSalesLayoutSalesOpportunityRoute:
      ProtectedDashboardSalesLayoutSalesOpportunityRoute,
  }

const ProtectedDashboardSalesLayoutRouteWithChildren =
  ProtectedDashboardSalesLayoutRoute._addFileChildren(
    ProtectedDashboardSalesLayoutRouteChildren,
  )

interface ProtectedDashboardSalesRouteChildren {
  ProtectedDashboardSalesLayoutRoute: typeof ProtectedDashboardSalesLayoutRouteWithChildren
  ProtectedDashboardSalesDirectSaleNewRoute: typeof ProtectedDashboardSalesDirectSaleNewRoute
  ProtectedDashboardSalesSalesOpportunityIdIndexRoute: typeof ProtectedDashboardSalesSalesOpportunityIdIndexRoute
  ProtectedDashboardSalesNewIndexRoute: typeof ProtectedDashboardSalesNewIndexRoute
  ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute
  ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute
  ProtectedDashboardSalesInspectionCustomerIdNewRoute: typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  ProtectedDashboardSalesInspectionInspectionIdUpdateRoute: typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute: typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute
  ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute: typeof ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute
}

const ProtectedDashboardSalesRouteChildren: ProtectedDashboardSalesRouteChildren =
  {
    ProtectedDashboardSalesLayoutRoute:
      ProtectedDashboardSalesLayoutRouteWithChildren,
    ProtectedDashboardSalesDirectSaleNewRoute:
      ProtectedDashboardSalesDirectSaleNewRoute,
    ProtectedDashboardSalesSalesOpportunityIdIndexRoute:
      ProtectedDashboardSalesSalesOpportunityIdIndexRoute,
    ProtectedDashboardSalesNewIndexRoute: ProtectedDashboardSalesNewIndexRoute,
    ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute:
      ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute,
    ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute:
      ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute,
    ProtectedDashboardSalesInspectionCustomerIdNewRoute:
      ProtectedDashboardSalesInspectionCustomerIdNewRoute,
    ProtectedDashboardSalesInspectionInspectionIdUpdateRoute:
      ProtectedDashboardSalesInspectionInspectionIdUpdateRoute,
    ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute:
      ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute,
    ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute:
      ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute,
    ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute:
      ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute,
  }

const ProtectedDashboardSalesRouteWithChildren =
  ProtectedDashboardSalesRoute._addFileChildren(
    ProtectedDashboardSalesRouteChildren,
  )

interface ProtectedDashboardSettingsLayoutRouteChildren {
  ProtectedDashboardSettingsLayoutChecklistTemplateRoute: typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  ProtectedDashboardSettingsLayoutCompanyRoute: typeof ProtectedDashboardSettingsLayoutCompanyRoute
  ProtectedDashboardSettingsLayoutDocumentationTemplateRoute: typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  ProtectedDashboardSettingsLayoutEquipmentRoute: typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  ProtectedDashboardSettingsLayoutIntegrationRoute: typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  ProtectedDashboardSettingsLayoutOrderRoute: typeof ProtectedDashboardSettingsLayoutOrderRoute
  ProtectedDashboardSettingsLayoutProjectRoute: typeof ProtectedDashboardSettingsLayoutProjectRoute
  ProtectedDashboardSettingsLayoutTaskTemplateRoute: typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  ProtectedDashboardSettingsLayoutVideoGuideRoute: typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
}

const ProtectedDashboardSettingsLayoutRouteChildren: ProtectedDashboardSettingsLayoutRouteChildren =
  {
    ProtectedDashboardSettingsLayoutChecklistTemplateRoute:
      ProtectedDashboardSettingsLayoutChecklistTemplateRoute,
    ProtectedDashboardSettingsLayoutCompanyRoute:
      ProtectedDashboardSettingsLayoutCompanyRoute,
    ProtectedDashboardSettingsLayoutDocumentationTemplateRoute:
      ProtectedDashboardSettingsLayoutDocumentationTemplateRoute,
    ProtectedDashboardSettingsLayoutEquipmentRoute:
      ProtectedDashboardSettingsLayoutEquipmentRoute,
    ProtectedDashboardSettingsLayoutIntegrationRoute:
      ProtectedDashboardSettingsLayoutIntegrationRoute,
    ProtectedDashboardSettingsLayoutOrderRoute:
      ProtectedDashboardSettingsLayoutOrderRoute,
    ProtectedDashboardSettingsLayoutProjectRoute:
      ProtectedDashboardSettingsLayoutProjectRoute,
    ProtectedDashboardSettingsLayoutTaskTemplateRoute:
      ProtectedDashboardSettingsLayoutTaskTemplateRoute,
    ProtectedDashboardSettingsLayoutVideoGuideRoute:
      ProtectedDashboardSettingsLayoutVideoGuideRoute,
  }

const ProtectedDashboardSettingsLayoutRouteWithChildren =
  ProtectedDashboardSettingsLayoutRoute._addFileChildren(
    ProtectedDashboardSettingsLayoutRouteChildren,
  )

interface ProtectedDashboardSettingsRouteChildren {
  ProtectedDashboardSettingsLayoutRoute: typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  ProtectedDashboardSettingsProjectPhasesEditRoute: typeof ProtectedDashboardSettingsProjectPhasesEditRoute
}

const ProtectedDashboardSettingsRouteChildren: ProtectedDashboardSettingsRouteChildren =
  {
    ProtectedDashboardSettingsLayoutRoute:
      ProtectedDashboardSettingsLayoutRouteWithChildren,
    ProtectedDashboardSettingsProjectPhasesEditRoute:
      ProtectedDashboardSettingsProjectPhasesEditRoute,
  }

const ProtectedDashboardSettingsRouteWithChildren =
  ProtectedDashboardSettingsRoute._addFileChildren(
    ProtectedDashboardSettingsRouteChildren,
  )

interface ProtectedDashboardOrdersOrderIdLayoutRouteChildren {
  ProtectedDashboardOrdersOrderIdLayoutChecklistRoute: typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  ProtectedDashboardOrdersOrderIdLayoutDeviationRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute: typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  ProtectedDashboardOrdersOrderIdLayoutOverviewRoute: typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  ProtectedDashboardOrdersOrderIdLayoutProductRoute: typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  ProtectedDashboardOrdersOrderIdLayoutTimeRoute: typeof ProtectedDashboardOrdersOrderIdLayoutTimeRoute
}

const ProtectedDashboardOrdersOrderIdLayoutRouteChildren: ProtectedDashboardOrdersOrderIdLayoutRouteChildren =
  {
    ProtectedDashboardOrdersOrderIdLayoutChecklistRoute:
      ProtectedDashboardOrdersOrderIdLayoutChecklistRoute,
    ProtectedDashboardOrdersOrderIdLayoutDeviationRoute:
      ProtectedDashboardOrdersOrderIdLayoutDeviationRoute,
    ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute:
      ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute,
    ProtectedDashboardOrdersOrderIdLayoutOverviewRoute:
      ProtectedDashboardOrdersOrderIdLayoutOverviewRoute,
    ProtectedDashboardOrdersOrderIdLayoutProductRoute:
      ProtectedDashboardOrdersOrderIdLayoutProductRoute,
    ProtectedDashboardOrdersOrderIdLayoutTimeRoute:
      ProtectedDashboardOrdersOrderIdLayoutTimeRoute,
  }

const ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren =
  ProtectedDashboardOrdersOrderIdLayoutRoute._addFileChildren(
    ProtectedDashboardOrdersOrderIdLayoutRouteChildren,
  )

interface ProtectedDashboardOrdersOrderIdRouteChildren {
  ProtectedDashboardOrdersOrderIdLayoutRoute: typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  ProtectedDashboardOrdersOrderIdReportRoute: typeof ProtectedDashboardOrdersOrderIdReportRoute
  ProtectedDashboardOrdersOrderIdUpdateRoute: typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  ProtectedDashboardOrdersOrderIdProductsAddRoute: typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
}

const ProtectedDashboardOrdersOrderIdRouteChildren: ProtectedDashboardOrdersOrderIdRouteChildren =
  {
    ProtectedDashboardOrdersOrderIdLayoutRoute:
      ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren,
    ProtectedDashboardOrdersOrderIdReportRoute:
      ProtectedDashboardOrdersOrderIdReportRoute,
    ProtectedDashboardOrdersOrderIdUpdateRoute:
      ProtectedDashboardOrdersOrderIdUpdateRoute,
    ProtectedDashboardOrdersOrderIdProductsAddRoute:
      ProtectedDashboardOrdersOrderIdProductsAddRoute,
  }

const ProtectedDashboardOrdersOrderIdRouteWithChildren =
  ProtectedDashboardOrdersOrderIdRoute._addFileChildren(
    ProtectedDashboardOrdersOrderIdRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

const ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren: ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute,
    ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute,
  }

const ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren =
  ProtectedDashboardProjectProjectIdLayoutLayoutRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdLayoutLayoutRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdLayoutRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutLayoutRoute: typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  ProtectedDashboardProjectProjectIdLayoutChecklistRoute: typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  ProtectedDashboardProjectProjectIdLayoutDeviationRoute: typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  ProtectedDashboardProjectProjectIdLayoutDocumentationRoute: typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  ProtectedDashboardProjectProjectIdLayoutEhsRoute: typeof ProtectedDashboardProjectProjectIdLayoutEhsRoute
  ProtectedDashboardProjectProjectIdLayoutOverviewRoute: typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  ProtectedDashboardProjectProjectIdLayoutProductRoute: typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute: typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  ProtectedDashboardProjectProjectIdLayoutSubProjectRoute: typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  ProtectedDashboardProjectProjectIdLayoutTaskRoute: typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
}

const ProtectedDashboardProjectProjectIdLayoutRouteChildren: ProtectedDashboardProjectProjectIdLayoutRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutLayoutRoute:
      ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren,
    ProtectedDashboardProjectProjectIdLayoutChecklistRoute:
      ProtectedDashboardProjectProjectIdLayoutChecklistRoute,
    ProtectedDashboardProjectProjectIdLayoutDeviationRoute:
      ProtectedDashboardProjectProjectIdLayoutDeviationRoute,
    ProtectedDashboardProjectProjectIdLayoutDocumentationRoute:
      ProtectedDashboardProjectProjectIdLayoutDocumentationRoute,
    ProtectedDashboardProjectProjectIdLayoutEhsRoute:
      ProtectedDashboardProjectProjectIdLayoutEhsRoute,
    ProtectedDashboardProjectProjectIdLayoutOverviewRoute:
      ProtectedDashboardProjectProjectIdLayoutOverviewRoute,
    ProtectedDashboardProjectProjectIdLayoutProductRoute:
      ProtectedDashboardProjectProjectIdLayoutProductRoute,
    ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute:
      ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute,
    ProtectedDashboardProjectProjectIdLayoutSubProjectRoute:
      ProtectedDashboardProjectProjectIdLayoutSubProjectRoute,
    ProtectedDashboardProjectProjectIdLayoutTaskRoute:
      ProtectedDashboardProjectProjectIdLayoutTaskRoute,
  }

const ProtectedDashboardProjectProjectIdLayoutRouteWithChildren =
  ProtectedDashboardProjectProjectIdLayoutRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdLayoutRouteChildren,
  )

interface ProtectedDashboardProjectProjectIdRouteChildren {
  ProtectedDashboardProjectProjectIdLayoutRoute: typeof ProtectedDashboardProjectProjectIdLayoutRouteWithChildren
  ProtectedDashboardProjectProjectIdReportRoute: typeof ProtectedDashboardProjectProjectIdReportRoute
  ProtectedDashboardProjectProjectIdProductsAddRoute: typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  ProtectedDashboardProjectProjectIdTaskTaskIdRoute: typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  ProtectedDashboardProjectProjectIdPhasesIndexRoute: typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  ProtectedDashboardProjectProjectIdWorkersIndexRoute: typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
}

const ProtectedDashboardProjectProjectIdRouteChildren: ProtectedDashboardProjectProjectIdRouteChildren =
  {
    ProtectedDashboardProjectProjectIdLayoutRoute:
      ProtectedDashboardProjectProjectIdLayoutRouteWithChildren,
    ProtectedDashboardProjectProjectIdReportRoute:
      ProtectedDashboardProjectProjectIdReportRoute,
    ProtectedDashboardProjectProjectIdProductsAddRoute:
      ProtectedDashboardProjectProjectIdProductsAddRoute,
    ProtectedDashboardProjectProjectIdTaskTaskIdRoute:
      ProtectedDashboardProjectProjectIdTaskTaskIdRoute,
    ProtectedDashboardProjectProjectIdPhasesIndexRoute:
      ProtectedDashboardProjectProjectIdPhasesIndexRoute,
    ProtectedDashboardProjectProjectIdWorkersIndexRoute:
      ProtectedDashboardProjectProjectIdWorkersIndexRoute,
  }

const ProtectedDashboardProjectProjectIdRouteWithChildren =
  ProtectedDashboardProjectProjectIdRoute._addFileChildren(
    ProtectedDashboardProjectProjectIdRouteChildren,
  )

interface ProtectedDashboardRouteChildren {
  ProtectedDashboardActivitiesNewRoute: typeof ProtectedDashboardActivitiesNewRoute
  ProtectedDashboardChecklistNewRoute: typeof ProtectedDashboardChecklistNewRoute
  ProtectedDashboardCustomersNewRoute: typeof ProtectedDashboardCustomersNewRoute
  ProtectedDashboardDeviationNewRoute: typeof ProtectedDashboardDeviationNewRoute
  ProtectedDashboardEquipmentNewRoute: typeof ProtectedDashboardEquipmentNewRoute
  ProtectedDashboardFacilityNewRoute: typeof ProtectedDashboardFacilityNewRoute
  ProtectedDashboardInternalRoutinesNewRoute: typeof ProtectedDashboardInternalRoutinesNewRoute
  ProtectedDashboardSalesRoute: typeof ProtectedDashboardSalesRouteWithChildren
  ProtectedDashboardServiceContractNewRoute: typeof ProtectedDashboardServiceContractNewRoute
  ProtectedDashboardSettingsRoute: typeof ProtectedDashboardSettingsRouteWithChildren
  ProtectedDashboardActivitiesIndexRoute: typeof ProtectedDashboardActivitiesIndexRoute
  ProtectedDashboardCustomersIndexRoute: typeof ProtectedDashboardCustomersIndexRoute
  ProtectedDashboardEhsIndexRoute: typeof ProtectedDashboardEhsIndexRoute
  ProtectedDashboardEmployeesIndexRoute: typeof ProtectedDashboardEmployeesIndexRoute
  ProtectedDashboardInvoiceIndexRoute: typeof ProtectedDashboardInvoiceIndexRoute
  ProtectedDashboardOrdersIndexRoute: typeof ProtectedDashboardOrdersIndexRoute
  ProtectedDashboardOverviewIndexRoute: typeof ProtectedDashboardOverviewIndexRoute
  ProtectedDashboardPricesIndexRoute: typeof ProtectedDashboardPricesIndexRoute
  ProtectedDashboardProductsIndexRoute: typeof ProtectedDashboardProductsIndexRoute
  ProtectedDashboardProjectIndexRoute: typeof ProtectedDashboardProjectIndexRoute
  ProtectedDashboardTimeIndexRoute: typeof ProtectedDashboardTimeIndexRoute
  ProtectedDashboardActivitiesActivityIdUpdateRoute: typeof ProtectedDashboardActivitiesActivityIdUpdateRoute
  ProtectedDashboardChecklistChecklistIdUpdateRoute: typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  ProtectedDashboardCustomersCustomerIdEditRoute: typeof ProtectedDashboardCustomersCustomerIdEditRoute
  ProtectedDashboardDeviationDeviationIdUpdateRoute: typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  ProtectedDashboardEhsEhsIdUpdateRoute: typeof ProtectedDashboardEhsEhsIdUpdateRoute
  ProtectedDashboardEhsTemplateIdNewRoute: typeof ProtectedDashboardEhsTemplateIdNewRoute
  ProtectedDashboardEhsTemplateNewRoute: typeof ProtectedDashboardEhsTemplateNewRoute
  ProtectedDashboardEquipmentEquipmentIdUpdateRoute: typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  ProtectedDashboardFacilityFacilityIdDetailsRoute: typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  ProtectedDashboardFacilityFacilityIdUpdateRoute: typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute: typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  ProtectedDashboardLocationResourceIdNewRoute: typeof ProtectedDashboardLocationResourceIdNewRoute
  ProtectedDashboardLocationResourceIdUpdateRoute: typeof ProtectedDashboardLocationResourceIdUpdateRoute
  ProtectedDashboardOrdersOrderIdRoute: typeof ProtectedDashboardOrdersOrderIdRouteWithChildren
  ProtectedDashboardProductsProductIdUpdateRoute: typeof ProtectedDashboardProductsProductIdUpdateRoute
  ProtectedDashboardProductsBundlesNewRoute: typeof ProtectedDashboardProductsBundlesNewRoute
  ProtectedDashboardProjectProjectIdRoute: typeof ProtectedDashboardProjectProjectIdRouteWithChildren
  ProtectedDashboardResourcesEmployeeIdUpdateRoute: typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  ProtectedDashboardServiceContractServiceContractIdUpdateRoute: typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  ProtectedDashboardCustomersCustomerIdIndexRoute: typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  ProtectedDashboardInvoiceNewIndexRoute: typeof ProtectedDashboardInvoiceNewIndexRoute
  ProtectedDashboardOrdersNewIndexRoute: typeof ProtectedDashboardOrdersNewIndexRoute
  ProtectedDashboardProductsNewIndexRoute: typeof ProtectedDashboardProductsNewIndexRoute
  ProtectedDashboardProjectNewIndexRoute: typeof ProtectedDashboardProjectNewIndexRoute
  ProtectedDashboardTimeNewIndexRoute: typeof ProtectedDashboardTimeNewIndexRoute
  ProtectedDashboardCustomersCustomerIdOrderNewRoute: typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  ProtectedDashboardEhsTemplateTemplateIdUpdateRoute: typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  ProtectedDashboardOrdersTemplateTemplateIdProductRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  ProtectedDashboardProductsBundlesBundleIdUpdateRoute: typeof ProtectedDashboardProductsBundlesBundleIdUpdateRoute
  ProtectedDashboardTimeApprovalOrderOrderIdRoute: typeof ProtectedDashboardTimeApprovalOrderOrderIdRoute
  ProtectedDashboardTimeApprovalProjectProjectIdRoute: typeof ProtectedDashboardTimeApprovalProjectProjectIdRoute
  ProtectedDashboardTimeApprovalUserUserIdRoute: typeof ProtectedDashboardTimeApprovalUserUserIdRoute
  ProtectedDashboardOrdersTemplateTemplateIdIndexRoute: typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
}

const ProtectedDashboardRouteChildren: ProtectedDashboardRouteChildren = {
  ProtectedDashboardActivitiesNewRoute: ProtectedDashboardActivitiesNewRoute,
  ProtectedDashboardChecklistNewRoute: ProtectedDashboardChecklistNewRoute,
  ProtectedDashboardCustomersNewRoute: ProtectedDashboardCustomersNewRoute,
  ProtectedDashboardDeviationNewRoute: ProtectedDashboardDeviationNewRoute,
  ProtectedDashboardEquipmentNewRoute: ProtectedDashboardEquipmentNewRoute,
  ProtectedDashboardFacilityNewRoute: ProtectedDashboardFacilityNewRoute,
  ProtectedDashboardInternalRoutinesNewRoute:
    ProtectedDashboardInternalRoutinesNewRoute,
  ProtectedDashboardSalesRoute: ProtectedDashboardSalesRouteWithChildren,
  ProtectedDashboardServiceContractNewRoute:
    ProtectedDashboardServiceContractNewRoute,
  ProtectedDashboardSettingsRoute: ProtectedDashboardSettingsRouteWithChildren,
  ProtectedDashboardActivitiesIndexRoute:
    ProtectedDashboardActivitiesIndexRoute,
  ProtectedDashboardCustomersIndexRoute: ProtectedDashboardCustomersIndexRoute,
  ProtectedDashboardEhsIndexRoute: ProtectedDashboardEhsIndexRoute,
  ProtectedDashboardEmployeesIndexRoute: ProtectedDashboardEmployeesIndexRoute,
  ProtectedDashboardInvoiceIndexRoute: ProtectedDashboardInvoiceIndexRoute,
  ProtectedDashboardOrdersIndexRoute: ProtectedDashboardOrdersIndexRoute,
  ProtectedDashboardOverviewIndexRoute: ProtectedDashboardOverviewIndexRoute,
  ProtectedDashboardPricesIndexRoute: ProtectedDashboardPricesIndexRoute,
  ProtectedDashboardProductsIndexRoute: ProtectedDashboardProductsIndexRoute,
  ProtectedDashboardProjectIndexRoute: ProtectedDashboardProjectIndexRoute,
  ProtectedDashboardTimeIndexRoute: ProtectedDashboardTimeIndexRoute,
  ProtectedDashboardActivitiesActivityIdUpdateRoute:
    ProtectedDashboardActivitiesActivityIdUpdateRoute,
  ProtectedDashboardChecklistChecklistIdUpdateRoute:
    ProtectedDashboardChecklistChecklistIdUpdateRoute,
  ProtectedDashboardCustomersCustomerIdEditRoute:
    ProtectedDashboardCustomersCustomerIdEditRoute,
  ProtectedDashboardDeviationDeviationIdUpdateRoute:
    ProtectedDashboardDeviationDeviationIdUpdateRoute,
  ProtectedDashboardEhsEhsIdUpdateRoute: ProtectedDashboardEhsEhsIdUpdateRoute,
  ProtectedDashboardEhsTemplateIdNewRoute:
    ProtectedDashboardEhsTemplateIdNewRoute,
  ProtectedDashboardEhsTemplateNewRoute: ProtectedDashboardEhsTemplateNewRoute,
  ProtectedDashboardEquipmentEquipmentIdUpdateRoute:
    ProtectedDashboardEquipmentEquipmentIdUpdateRoute,
  ProtectedDashboardFacilityFacilityIdDetailsRoute:
    ProtectedDashboardFacilityFacilityIdDetailsRoute,
  ProtectedDashboardFacilityFacilityIdUpdateRoute:
    ProtectedDashboardFacilityFacilityIdUpdateRoute,
  ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute:
    ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute,
  ProtectedDashboardLocationResourceIdNewRoute:
    ProtectedDashboardLocationResourceIdNewRoute,
  ProtectedDashboardLocationResourceIdUpdateRoute:
    ProtectedDashboardLocationResourceIdUpdateRoute,
  ProtectedDashboardOrdersOrderIdRoute:
    ProtectedDashboardOrdersOrderIdRouteWithChildren,
  ProtectedDashboardProductsProductIdUpdateRoute:
    ProtectedDashboardProductsProductIdUpdateRoute,
  ProtectedDashboardProductsBundlesNewRoute:
    ProtectedDashboardProductsBundlesNewRoute,
  ProtectedDashboardProjectProjectIdRoute:
    ProtectedDashboardProjectProjectIdRouteWithChildren,
  ProtectedDashboardResourcesEmployeeIdUpdateRoute:
    ProtectedDashboardResourcesEmployeeIdUpdateRoute,
  ProtectedDashboardServiceContractServiceContractIdUpdateRoute:
    ProtectedDashboardServiceContractServiceContractIdUpdateRoute,
  ProtectedDashboardCustomersCustomerIdIndexRoute:
    ProtectedDashboardCustomersCustomerIdIndexRoute,
  ProtectedDashboardInvoiceNewIndexRoute:
    ProtectedDashboardInvoiceNewIndexRoute,
  ProtectedDashboardOrdersNewIndexRoute: ProtectedDashboardOrdersNewIndexRoute,
  ProtectedDashboardProductsNewIndexRoute:
    ProtectedDashboardProductsNewIndexRoute,
  ProtectedDashboardProjectNewIndexRoute:
    ProtectedDashboardProjectNewIndexRoute,
  ProtectedDashboardTimeNewIndexRoute: ProtectedDashboardTimeNewIndexRoute,
  ProtectedDashboardCustomersCustomerIdOrderNewRoute:
    ProtectedDashboardCustomersCustomerIdOrderNewRoute,
  ProtectedDashboardEhsTemplateTemplateIdUpdateRoute:
    ProtectedDashboardEhsTemplateTemplateIdUpdateRoute,
  ProtectedDashboardOrdersTemplateTemplateIdProductRoute:
    ProtectedDashboardOrdersTemplateTemplateIdProductRoute,
  ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute:
    ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute,
  ProtectedDashboardProductsBundlesBundleIdUpdateRoute:
    ProtectedDashboardProductsBundlesBundleIdUpdateRoute,
  ProtectedDashboardTimeApprovalOrderOrderIdRoute:
    ProtectedDashboardTimeApprovalOrderOrderIdRoute,
  ProtectedDashboardTimeApprovalProjectProjectIdRoute:
    ProtectedDashboardTimeApprovalProjectProjectIdRoute,
  ProtectedDashboardTimeApprovalUserUserIdRoute:
    ProtectedDashboardTimeApprovalUserUserIdRoute,
  ProtectedDashboardOrdersTemplateTemplateIdIndexRoute:
    ProtectedDashboardOrdersTemplateTemplateIdIndexRoute,
}

const ProtectedDashboardRouteWithChildren =
  ProtectedDashboardRoute._addFileChildren(ProtectedDashboardRouteChildren)

interface ProtectedTrialRouteChildren {
  ProtectedTrialConfirmationRoute: typeof ProtectedTrialConfirmationRoute
  ProtectedTrialExpiredRoute: typeof ProtectedTrialExpiredRoute
  ProtectedTrialSubscribeRoute: typeof ProtectedTrialSubscribeRoute
}

const ProtectedTrialRouteChildren: ProtectedTrialRouteChildren = {
  ProtectedTrialConfirmationRoute: ProtectedTrialConfirmationRoute,
  ProtectedTrialExpiredRoute: ProtectedTrialExpiredRoute,
  ProtectedTrialSubscribeRoute: ProtectedTrialSubscribeRoute,
}

const ProtectedTrialRouteWithChildren = ProtectedTrialRoute._addFileChildren(
  ProtectedTrialRouteChildren,
)

interface ProtectedRouteChildren {
  ProtectedCreateCompanyRoute: typeof ProtectedCreateCompanyRoute
  ProtectedDashboardRoute: typeof ProtectedDashboardRouteWithChildren
  ProtectedInvitationsRoute: typeof ProtectedInvitationsRoute
  ProtectedTrialRoute: typeof ProtectedTrialRouteWithChildren
  ProtectedUserInfoRoute: typeof ProtectedUserInfoRoute
  ProtectedWelcomeRoute: typeof ProtectedWelcomeRoute
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedCreateCompanyRoute: ProtectedCreateCompanyRoute,
  ProtectedDashboardRoute: ProtectedDashboardRouteWithChildren,
  ProtectedInvitationsRoute: ProtectedInvitationsRoute,
  ProtectedTrialRoute: ProtectedTrialRouteWithChildren,
  ProtectedUserInfoRoute: ProtectedUserInfoRoute,
  ProtectedWelcomeRoute: ProtectedWelcomeRoute,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

interface UnauthenticatedRouteChildren {
  UnauthenticatedCreateAccountRoute: typeof UnauthenticatedCreateAccountRoute
  UnauthenticatedLoginRoute: typeof UnauthenticatedLoginRoute
  UnauthenticatedResetPasswordRoute: typeof UnauthenticatedResetPasswordRoute
  UnauthenticatedIndexRoute: typeof UnauthenticatedIndexRoute
  UnauthenticatedOfferPreviewRoute: typeof UnauthenticatedOfferPreviewRoute
  UnauthenticatedOfferTemplateRoute: typeof UnauthenticatedOfferTemplateRoute
  UnauthenticatedOfferPathUriDetailsRoute: typeof UnauthenticatedOfferPathUriDetailsRoute
  UnauthenticatedOfferPathUriReceiptRoute: typeof UnauthenticatedOfferPathUriReceiptRoute
  UnauthenticatedOfferPathUriIndexRoute: typeof UnauthenticatedOfferPathUriIndexRoute
}

const UnauthenticatedRouteChildren: UnauthenticatedRouteChildren = {
  UnauthenticatedCreateAccountRoute: UnauthenticatedCreateAccountRoute,
  UnauthenticatedLoginRoute: UnauthenticatedLoginRoute,
  UnauthenticatedResetPasswordRoute: UnauthenticatedResetPasswordRoute,
  UnauthenticatedIndexRoute: UnauthenticatedIndexRoute,
  UnauthenticatedOfferPreviewRoute: UnauthenticatedOfferPreviewRoute,
  UnauthenticatedOfferTemplateRoute: UnauthenticatedOfferTemplateRoute,
  UnauthenticatedOfferPathUriDetailsRoute:
    UnauthenticatedOfferPathUriDetailsRoute,
  UnauthenticatedOfferPathUriReceiptRoute:
    UnauthenticatedOfferPathUriReceiptRoute,
  UnauthenticatedOfferPathUriIndexRoute: UnauthenticatedOfferPathUriIndexRoute,
}

const UnauthenticatedRouteWithChildren = UnauthenticatedRoute._addFileChildren(
  UnauthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  "": typeof UnauthenticatedRouteWithChildren
  "/createCompany": typeof ProtectedCreateCompanyRoute
  "/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/invitations": typeof ProtectedInvitationsRoute
  "/trial": typeof ProtectedTrialRouteWithChildren
  "/userInfo": typeof ProtectedUserInfoRoute
  "/welcome": typeof ProtectedWelcomeRoute
  "/createAccount": typeof UnauthenticatedCreateAccountRoute
  "/login": typeof UnauthenticatedLoginRoute
  "/resetPassword": typeof UnauthenticatedResetPasswordRoute
  "/": typeof UnauthenticatedIndexRoute
  "/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/trial/expired": typeof ProtectedTrialExpiredRoute
  "/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/offer/preview": typeof UnauthenticatedOfferPreviewRoute
  "/offer/template": typeof UnauthenticatedOfferTemplateRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId": typeof GuestCustomerIdIndexRoute
  "/dashboard/activities/new": typeof ProtectedDashboardActivitiesNewRoute
  "/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/dashboard/sales": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/dashboard/settings": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/offer/$pathUri/details": typeof UnauthenticatedOfferPathUriDetailsRoute
  "/offer/$pathUri/receipt": typeof UnauthenticatedOfferPathUriReceiptRoute
  "/dashboard/activities": typeof ProtectedDashboardActivitiesIndexRoute
  "/dashboard/customers": typeof ProtectedDashboardCustomersIndexRoute
  "/dashboard/ehs": typeof ProtectedDashboardEhsIndexRoute
  "/dashboard/employees": typeof ProtectedDashboardEmployeesIndexRoute
  "/dashboard/invoice": typeof ProtectedDashboardInvoiceIndexRoute
  "/dashboard/orders": typeof ProtectedDashboardOrdersIndexRoute
  "/dashboard/overview": typeof ProtectedDashboardOverviewIndexRoute
  "/dashboard/prices": typeof ProtectedDashboardPricesIndexRoute
  "/dashboard/products": typeof ProtectedDashboardProductsIndexRoute
  "/dashboard/project": typeof ProtectedDashboardProjectIndexRoute
  "/dashboard/time": typeof ProtectedDashboardTimeIndexRoute
  "/offer/$pathUri": typeof UnauthenticatedOfferPathUriIndexRoute
  "/dashboard/activities/$activityId/update": typeof ProtectedDashboardActivitiesActivityIdUpdateRoute
  "/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/dashboard/orders/$orderId/report": typeof ProtectedDashboardOrdersOrderIdReportRoute
  "/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/dashboard/products/bundles/new": typeof ProtectedDashboardProductsBundlesNewRoute
  "/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/dashboard/project/$projectId/report": typeof ProtectedDashboardProjectProjectIdReportRoute
  "/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/dashboard/sales/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/dashboard/sales/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/dashboard/sales/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/dashboard/settings/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/dashboard/settings/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/dashboard/settings/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/dashboard/settings/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/dashboard/settings/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/dashboard/settings/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/dashboard/settings/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/dashboard/settings/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/dashboard/settings/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/dashboard/customers/$customerId": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/dashboard/invoice/new": typeof ProtectedDashboardInvoiceNewIndexRoute
  "/dashboard/orders/new": typeof ProtectedDashboardOrdersNewIndexRoute
  "/dashboard/products/new": typeof ProtectedDashboardProductsNewIndexRoute
  "/dashboard/project/new": typeof ProtectedDashboardProjectNewIndexRoute
  "/dashboard/sales/$salesOpportunityId": typeof ProtectedDashboardSalesSalesOpportunityIdIndexRoute
  "/dashboard/sales/new": typeof ProtectedDashboardSalesNewIndexRoute
  "/dashboard/time/new": typeof ProtectedDashboardTimeNewIndexRoute
  "/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/dashboard/orders/$orderId/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/dashboard/orders/$orderId/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/dashboard/orders/$orderId/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/dashboard/orders/$orderId/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/dashboard/orders/$orderId/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/dashboard/orders/$orderId/time": typeof ProtectedDashboardOrdersOrderIdLayoutTimeRoute
  "/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/dashboard/products/bundles/$bundleId/update": typeof ProtectedDashboardProductsBundlesBundleIdUpdateRoute
  "/dashboard/project/$projectId/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/dashboard/project/$projectId/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/dashboard/project/$projectId/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/dashboard/project/$projectId/ehs": typeof ProtectedDashboardProjectProjectIdLayoutEhsRoute
  "/dashboard/project/$projectId/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/dashboard/project/$projectId/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/dashboard/project/$projectId/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/dashboard/project/$projectId/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/dashboard/project/$projectId/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/dashboard/sales/$salesOpportunityId/offer-file/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute
  "/dashboard/sales/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute
  "/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/dashboard/time/approval/order/$orderId": typeof ProtectedDashboardTimeApprovalOrderOrderIdRoute
  "/dashboard/time/approval/project/$projectId": typeof ProtectedDashboardTimeApprovalProjectProjectIdRoute
  "/dashboard/time/approval/user/$userId": typeof ProtectedDashboardTimeApprovalUserUserIdRoute
  "/dashboard/orders/template/$templateId": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/dashboard/project/$projectId/phases": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/dashboard/project/$projectId/workers": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute
  "/dashboard/sales/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute
  "/dashboard/project/$projectId/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/dashboard/project/$projectId/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/dashboard/project/$projectId/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/dashboard/project/$projectId/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/dashboard/project/$projectId/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/dashboard/project/$projectId/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/dashboard/project/$projectId/economy": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRoutesByTo {
  "": typeof ProtectedRouteWithChildren
  "/createCompany": typeof ProtectedCreateCompanyRoute
  "/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/invitations": typeof ProtectedInvitationsRoute
  "/trial": typeof ProtectedTrialRouteWithChildren
  "/userInfo": typeof ProtectedUserInfoRoute
  "/welcome": typeof ProtectedWelcomeRoute
  "/createAccount": typeof UnauthenticatedCreateAccountRoute
  "/login": typeof UnauthenticatedLoginRoute
  "/resetPassword": typeof UnauthenticatedResetPasswordRoute
  "/": typeof UnauthenticatedIndexRoute
  "/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/trial/expired": typeof ProtectedTrialExpiredRoute
  "/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/offer/preview": typeof UnauthenticatedOfferPreviewRoute
  "/offer/template": typeof UnauthenticatedOfferTemplateRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId": typeof GuestCustomerIdIndexRoute
  "/dashboard/activities/new": typeof ProtectedDashboardActivitiesNewRoute
  "/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/dashboard/sales": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/dashboard/settings": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/offer/$pathUri/details": typeof UnauthenticatedOfferPathUriDetailsRoute
  "/offer/$pathUri/receipt": typeof UnauthenticatedOfferPathUriReceiptRoute
  "/dashboard/activities": typeof ProtectedDashboardActivitiesIndexRoute
  "/dashboard/customers": typeof ProtectedDashboardCustomersIndexRoute
  "/dashboard/ehs": typeof ProtectedDashboardEhsIndexRoute
  "/dashboard/employees": typeof ProtectedDashboardEmployeesIndexRoute
  "/dashboard/invoice": typeof ProtectedDashboardInvoiceIndexRoute
  "/dashboard/orders": typeof ProtectedDashboardOrdersIndexRoute
  "/dashboard/overview": typeof ProtectedDashboardOverviewIndexRoute
  "/dashboard/prices": typeof ProtectedDashboardPricesIndexRoute
  "/dashboard/products": typeof ProtectedDashboardProductsIndexRoute
  "/dashboard/project": typeof ProtectedDashboardProjectIndexRoute
  "/dashboard/time": typeof ProtectedDashboardTimeIndexRoute
  "/offer/$pathUri": typeof UnauthenticatedOfferPathUriIndexRoute
  "/dashboard/activities/$activityId/update": typeof ProtectedDashboardActivitiesActivityIdUpdateRoute
  "/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/dashboard/orders/$orderId/report": typeof ProtectedDashboardOrdersOrderIdReportRoute
  "/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/dashboard/products/bundles/new": typeof ProtectedDashboardProductsBundlesNewRoute
  "/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/dashboard/project/$projectId/report": typeof ProtectedDashboardProjectProjectIdReportRoute
  "/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/dashboard/sales/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/dashboard/sales/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/dashboard/sales/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/dashboard/settings/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/dashboard/settings/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/dashboard/settings/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/dashboard/settings/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/dashboard/settings/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/dashboard/settings/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/dashboard/settings/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/dashboard/settings/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/dashboard/settings/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/dashboard/customers/$customerId": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/dashboard/invoice/new": typeof ProtectedDashboardInvoiceNewIndexRoute
  "/dashboard/orders/new": typeof ProtectedDashboardOrdersNewIndexRoute
  "/dashboard/products/new": typeof ProtectedDashboardProductsNewIndexRoute
  "/dashboard/project/new": typeof ProtectedDashboardProjectNewIndexRoute
  "/dashboard/sales/$salesOpportunityId": typeof ProtectedDashboardSalesSalesOpportunityIdIndexRoute
  "/dashboard/sales/new": typeof ProtectedDashboardSalesNewIndexRoute
  "/dashboard/time/new": typeof ProtectedDashboardTimeNewIndexRoute
  "/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/dashboard/orders/$orderId/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/dashboard/orders/$orderId/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/dashboard/orders/$orderId/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/dashboard/orders/$orderId/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/dashboard/orders/$orderId/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/dashboard/orders/$orderId/time": typeof ProtectedDashboardOrdersOrderIdLayoutTimeRoute
  "/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/dashboard/products/bundles/$bundleId/update": typeof ProtectedDashboardProductsBundlesBundleIdUpdateRoute
  "/dashboard/project/$projectId/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/dashboard/project/$projectId/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/dashboard/project/$projectId/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/dashboard/project/$projectId/ehs": typeof ProtectedDashboardProjectProjectIdLayoutEhsRoute
  "/dashboard/project/$projectId/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/dashboard/project/$projectId/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/dashboard/project/$projectId/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/dashboard/project/$projectId/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/dashboard/project/$projectId/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/dashboard/sales/$salesOpportunityId/offer-file/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute
  "/dashboard/sales/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute
  "/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/dashboard/time/approval/order/$orderId": typeof ProtectedDashboardTimeApprovalOrderOrderIdRoute
  "/dashboard/time/approval/project/$projectId": typeof ProtectedDashboardTimeApprovalProjectProjectIdRoute
  "/dashboard/time/approval/user/$userId": typeof ProtectedDashboardTimeApprovalUserUserIdRoute
  "/dashboard/orders/template/$templateId": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/dashboard/project/$projectId/phases": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/dashboard/project/$projectId/workers": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute
  "/dashboard/sales/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute
  "/dashboard/project/$projectId/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/dashboard/project/$projectId/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/dashboard/project/$projectId/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/dashboard/project/$projectId/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/dashboard/project/$projectId/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/dashboard/project/$projectId/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/dashboard/project/$projectId/economy": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/_protected": typeof ProtectedRouteWithChildren
  "/_unauthenticated": typeof UnauthenticatedRouteWithChildren
  "/_protected/createCompany": typeof ProtectedCreateCompanyRoute
  "/_protected/dashboard": typeof ProtectedDashboardRouteWithChildren
  "/_protected/invitations": typeof ProtectedInvitationsRoute
  "/_protected/trial": typeof ProtectedTrialRouteWithChildren
  "/_protected/userInfo": typeof ProtectedUserInfoRoute
  "/_protected/welcome": typeof ProtectedWelcomeRoute
  "/_unauthenticated/createAccount": typeof UnauthenticatedCreateAccountRoute
  "/_unauthenticated/login": typeof UnauthenticatedLoginRoute
  "/_unauthenticated/resetPassword": typeof UnauthenticatedResetPasswordRoute
  "/_unauthenticated/": typeof UnauthenticatedIndexRoute
  "/_protected/trial/confirmation": typeof ProtectedTrialConfirmationRoute
  "/_protected/trial/expired": typeof ProtectedTrialExpiredRoute
  "/_protected/trial/subscribe": typeof ProtectedTrialSubscribeRoute
  "/_unauthenticated/offer/preview": typeof UnauthenticatedOfferPreviewRoute
  "/_unauthenticated/offer/template": typeof UnauthenticatedOfferTemplateRoute
  "/guest/order/$orderId": typeof GuestOrderOrderIdRoute
  "/guest/project/$projectId": typeof GuestProjectProjectIdRoute
  "/guest/$customerId/": typeof GuestCustomerIdIndexRoute
  "/_protected/dashboard/activities/new": typeof ProtectedDashboardActivitiesNewRoute
  "/_protected/dashboard/checklist/new": typeof ProtectedDashboardChecklistNewRoute
  "/_protected/dashboard/customers/new": typeof ProtectedDashboardCustomersNewRoute
  "/_protected/dashboard/deviation/new": typeof ProtectedDashboardDeviationNewRoute
  "/_protected/dashboard/equipment/new": typeof ProtectedDashboardEquipmentNewRoute
  "/_protected/dashboard/facility/new": typeof ProtectedDashboardFacilityNewRoute
  "/_protected/dashboard/internal-routines/new": typeof ProtectedDashboardInternalRoutinesNewRoute
  "/_protected/dashboard/sales": typeof ProtectedDashboardSalesRouteWithChildren
  "/_protected/dashboard/sales/_layout": typeof ProtectedDashboardSalesLayoutRouteWithChildren
  "/_protected/dashboard/service-contract/new": typeof ProtectedDashboardServiceContractNewRoute
  "/_protected/dashboard/settings": typeof ProtectedDashboardSettingsRouteWithChildren
  "/_protected/dashboard/settings/_layout": typeof ProtectedDashboardSettingsLayoutRouteWithChildren
  "/_unauthenticated/offer/$pathUri/details": typeof UnauthenticatedOfferPathUriDetailsRoute
  "/_unauthenticated/offer/$pathUri/receipt": typeof UnauthenticatedOfferPathUriReceiptRoute
  "/_protected/dashboard/activities/": typeof ProtectedDashboardActivitiesIndexRoute
  "/_protected/dashboard/customers/": typeof ProtectedDashboardCustomersIndexRoute
  "/_protected/dashboard/ehs/": typeof ProtectedDashboardEhsIndexRoute
  "/_protected/dashboard/employees/": typeof ProtectedDashboardEmployeesIndexRoute
  "/_protected/dashboard/invoice/": typeof ProtectedDashboardInvoiceIndexRoute
  "/_protected/dashboard/orders/": typeof ProtectedDashboardOrdersIndexRoute
  "/_protected/dashboard/overview/": typeof ProtectedDashboardOverviewIndexRoute
  "/_protected/dashboard/prices/": typeof ProtectedDashboardPricesIndexRoute
  "/_protected/dashboard/products/": typeof ProtectedDashboardProductsIndexRoute
  "/_protected/dashboard/project/": typeof ProtectedDashboardProjectIndexRoute
  "/_protected/dashboard/time/": typeof ProtectedDashboardTimeIndexRoute
  "/_unauthenticated/offer/$pathUri/": typeof UnauthenticatedOfferPathUriIndexRoute
  "/_protected/dashboard/activities/$activityId/update": typeof ProtectedDashboardActivitiesActivityIdUpdateRoute
  "/_protected/dashboard/checklist/$checklistId/update": typeof ProtectedDashboardChecklistChecklistIdUpdateRoute
  "/_protected/dashboard/customers/$customerId/edit": typeof ProtectedDashboardCustomersCustomerIdEditRoute
  "/_protected/dashboard/deviation/$deviationId/update": typeof ProtectedDashboardDeviationDeviationIdUpdateRoute
  "/_protected/dashboard/ehs/$ehsId/update": typeof ProtectedDashboardEhsEhsIdUpdateRoute
  "/_protected/dashboard/ehs/$templateId/new": typeof ProtectedDashboardEhsTemplateIdNewRoute
  "/_protected/dashboard/ehs/template/new": typeof ProtectedDashboardEhsTemplateNewRoute
  "/_protected/dashboard/equipment/$equipmentId/update": typeof ProtectedDashboardEquipmentEquipmentIdUpdateRoute
  "/_protected/dashboard/facility/$facilityId/details": typeof ProtectedDashboardFacilityFacilityIdDetailsRoute
  "/_protected/dashboard/facility/$facilityId/update": typeof ProtectedDashboardFacilityFacilityIdUpdateRoute
  "/_protected/dashboard/internal-routines/$routineId/update": typeof ProtectedDashboardInternalRoutinesRoutineIdUpdateRoute
  "/_protected/dashboard/location/$resourceId/new": typeof ProtectedDashboardLocationResourceIdNewRoute
  "/_protected/dashboard/location/$resourceId/update": typeof ProtectedDashboardLocationResourceIdUpdateRoute
  "/_protected/dashboard/orders/$orderId": typeof ProtectedDashboardOrdersOrderIdRouteWithChildren
  "/_protected/dashboard/orders/$orderId/_layout": typeof ProtectedDashboardOrdersOrderIdLayoutRouteWithChildren
  "/_protected/dashboard/orders/$orderId/report": typeof ProtectedDashboardOrdersOrderIdReportRoute
  "/_protected/dashboard/orders/$orderId/update": typeof ProtectedDashboardOrdersOrderIdUpdateRoute
  "/_protected/dashboard/products/$productId/update": typeof ProtectedDashboardProductsProductIdUpdateRoute
  "/_protected/dashboard/products/bundles/new": typeof ProtectedDashboardProductsBundlesNewRoute
  "/_protected/dashboard/project/$projectId": typeof ProtectedDashboardProjectProjectIdRouteWithChildren
  "/_protected/dashboard/project/$projectId/_layout": typeof ProtectedDashboardProjectProjectIdLayoutRouteWithChildren
  "/_protected/dashboard/project/$projectId/report": typeof ProtectedDashboardProjectProjectIdReportRoute
  "/_protected/dashboard/resources/$employeeId/update": typeof ProtectedDashboardResourcesEmployeeIdUpdateRoute
  "/_protected/dashboard/sales/_layout/direct-sale": typeof ProtectedDashboardSalesLayoutDirectSaleRoute
  "/_protected/dashboard/sales/_layout/report": typeof ProtectedDashboardSalesLayoutReportRoute
  "/_protected/dashboard/sales/_layout/sales-opportunity": typeof ProtectedDashboardSalesLayoutSalesOpportunityRoute
  "/_protected/dashboard/sales/direct-sale/new": typeof ProtectedDashboardSalesDirectSaleNewRoute
  "/_protected/dashboard/service-contract/$serviceContractId/update": typeof ProtectedDashboardServiceContractServiceContractIdUpdateRoute
  "/_protected/dashboard/settings/_layout/checklist-template": typeof ProtectedDashboardSettingsLayoutChecklistTemplateRoute
  "/_protected/dashboard/settings/_layout/company": typeof ProtectedDashboardSettingsLayoutCompanyRoute
  "/_protected/dashboard/settings/_layout/documentation-template": typeof ProtectedDashboardSettingsLayoutDocumentationTemplateRoute
  "/_protected/dashboard/settings/_layout/equipment": typeof ProtectedDashboardSettingsLayoutEquipmentRoute
  "/_protected/dashboard/settings/_layout/integration": typeof ProtectedDashboardSettingsLayoutIntegrationRoute
  "/_protected/dashboard/settings/_layout/order": typeof ProtectedDashboardSettingsLayoutOrderRoute
  "/_protected/dashboard/settings/_layout/project": typeof ProtectedDashboardSettingsLayoutProjectRoute
  "/_protected/dashboard/settings/_layout/task-template": typeof ProtectedDashboardSettingsLayoutTaskTemplateRoute
  "/_protected/dashboard/settings/_layout/video-guide": typeof ProtectedDashboardSettingsLayoutVideoGuideRoute
  "/_protected/dashboard/settings/project-phases/edit": typeof ProtectedDashboardSettingsProjectPhasesEditRoute
  "/_protected/dashboard/customers/$customerId/": typeof ProtectedDashboardCustomersCustomerIdIndexRoute
  "/_protected/dashboard/invoice/new/": typeof ProtectedDashboardInvoiceNewIndexRoute
  "/_protected/dashboard/orders/new/": typeof ProtectedDashboardOrdersNewIndexRoute
  "/_protected/dashboard/products/new/": typeof ProtectedDashboardProductsNewIndexRoute
  "/_protected/dashboard/project/new/": typeof ProtectedDashboardProjectNewIndexRoute
  "/_protected/dashboard/sales/$salesOpportunityId/": typeof ProtectedDashboardSalesSalesOpportunityIdIndexRoute
  "/_protected/dashboard/sales/new/": typeof ProtectedDashboardSalesNewIndexRoute
  "/_protected/dashboard/time/new/": typeof ProtectedDashboardTimeNewIndexRoute
  "/_protected/dashboard/customers/$customerId/order/new": typeof ProtectedDashboardCustomersCustomerIdOrderNewRoute
  "/_protected/dashboard/ehs/template/$templateId/update": typeof ProtectedDashboardEhsTemplateTemplateIdUpdateRoute
  "/_protected/dashboard/orders/$orderId/_layout/checklist": typeof ProtectedDashboardOrdersOrderIdLayoutChecklistRoute
  "/_protected/dashboard/orders/$orderId/_layout/deviation": typeof ProtectedDashboardOrdersOrderIdLayoutDeviationRoute
  "/_protected/dashboard/orders/$orderId/_layout/documentation": typeof ProtectedDashboardOrdersOrderIdLayoutDocumentationRoute
  "/_protected/dashboard/orders/$orderId/_layout/overview": typeof ProtectedDashboardOrdersOrderIdLayoutOverviewRoute
  "/_protected/dashboard/orders/$orderId/_layout/product": typeof ProtectedDashboardOrdersOrderIdLayoutProductRoute
  "/_protected/dashboard/orders/$orderId/_layout/time": typeof ProtectedDashboardOrdersOrderIdLayoutTimeRoute
  "/_protected/dashboard/orders/$orderId/products/add": typeof ProtectedDashboardOrdersOrderIdProductsAddRoute
  "/_protected/dashboard/orders/template/$templateId/product": typeof ProtectedDashboardOrdersTemplateTemplateIdProductRoute
  "/_protected/dashboard/orders/template/$templateId/update": typeof ProtectedDashboardOrdersTemplateTemplateIdUpdateRoute
  "/_protected/dashboard/products/bundles/$bundleId/update": typeof ProtectedDashboardProductsBundlesBundleIdUpdateRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout": typeof ProtectedDashboardProjectProjectIdLayoutLayoutRouteWithChildren
  "/_protected/dashboard/project/$projectId/_layout/checklist": typeof ProtectedDashboardProjectProjectIdLayoutChecklistRoute
  "/_protected/dashboard/project/$projectId/_layout/deviation": typeof ProtectedDashboardProjectProjectIdLayoutDeviationRoute
  "/_protected/dashboard/project/$projectId/_layout/documentation": typeof ProtectedDashboardProjectProjectIdLayoutDocumentationRoute
  "/_protected/dashboard/project/$projectId/_layout/ehs": typeof ProtectedDashboardProjectProjectIdLayoutEhsRoute
  "/_protected/dashboard/project/$projectId/_layout/overview": typeof ProtectedDashboardProjectProjectIdLayoutOverviewRoute
  "/_protected/dashboard/project/$projectId/_layout/product": typeof ProtectedDashboardProjectProjectIdLayoutProductRoute
  "/_protected/dashboard/project/$projectId/_layout/progress-plan": typeof ProtectedDashboardProjectProjectIdLayoutProgressPlanRoute
  "/_protected/dashboard/project/$projectId/_layout/sub-project": typeof ProtectedDashboardProjectProjectIdLayoutSubProjectRoute
  "/_protected/dashboard/project/$projectId/_layout/task": typeof ProtectedDashboardProjectProjectIdLayoutTaskRoute
  "/_protected/dashboard/project/$projectId/products/add": typeof ProtectedDashboardProjectProjectIdProductsAddRoute
  "/_protected/dashboard/project/$projectId/task/$taskId": typeof ProtectedDashboardProjectProjectIdTaskTaskIdRoute
  "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileNewRoute
  "/_protected/dashboard/sales/$salesOpportunityId/offer/new": typeof ProtectedDashboardSalesSalesOpportunityIdOfferNewRoute
  "/_protected/dashboard/sales/inspection/$customerId/new": typeof ProtectedDashboardSalesInspectionCustomerIdNewRoute
  "/_protected/dashboard/sales/inspection/$inspectionId/update": typeof ProtectedDashboardSalesInspectionInspectionIdUpdateRoute
  "/_protected/dashboard/sales/offer/$offerId/additional-work": typeof ProtectedDashboardSalesOfferOfferIdAdditionalWorkRoute
  "/_protected/dashboard/time/approval/order/$orderId": typeof ProtectedDashboardTimeApprovalOrderOrderIdRoute
  "/_protected/dashboard/time/approval/project/$projectId": typeof ProtectedDashboardTimeApprovalProjectProjectIdRoute
  "/_protected/dashboard/time/approval/user/$userId": typeof ProtectedDashboardTimeApprovalUserUserIdRoute
  "/_protected/dashboard/orders/template/$templateId/": typeof ProtectedDashboardOrdersTemplateTemplateIdIndexRoute
  "/_protected/dashboard/project/$projectId/phases/": typeof ProtectedDashboardProjectProjectIdPhasesIndexRoute
  "/_protected/dashboard/project/$projectId/workers/": typeof ProtectedDashboardProjectProjectIdWorkersIndexRoute
  "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferFileOfferIdUpdateRoute
  "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update": typeof ProtectedDashboardSalesSalesOpportunityIdOfferOfferIdUpdateRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyBudgetRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyChangeOrderRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyContractRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyInvoiceRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomySubcontractorRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyTimeRoute
  "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": typeof ProtectedDashboardProjectProjectIdLayoutLayoutEconomyIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ""
    | "/createCompany"
    | "/dashboard"
    | "/invitations"
    | "/trial"
    | "/userInfo"
    | "/welcome"
    | "/createAccount"
    | "/login"
    | "/resetPassword"
    | "/"
    | "/trial/confirmation"
    | "/trial/expired"
    | "/trial/subscribe"
    | "/offer/preview"
    | "/offer/template"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId"
    | "/dashboard/activities/new"
    | "/dashboard/checklist/new"
    | "/dashboard/customers/new"
    | "/dashboard/deviation/new"
    | "/dashboard/equipment/new"
    | "/dashboard/facility/new"
    | "/dashboard/internal-routines/new"
    | "/dashboard/sales"
    | "/dashboard/service-contract/new"
    | "/dashboard/settings"
    | "/offer/$pathUri/details"
    | "/offer/$pathUri/receipt"
    | "/dashboard/activities"
    | "/dashboard/customers"
    | "/dashboard/ehs"
    | "/dashboard/employees"
    | "/dashboard/invoice"
    | "/dashboard/orders"
    | "/dashboard/overview"
    | "/dashboard/prices"
    | "/dashboard/products"
    | "/dashboard/project"
    | "/dashboard/time"
    | "/offer/$pathUri"
    | "/dashboard/activities/$activityId/update"
    | "/dashboard/checklist/$checklistId/update"
    | "/dashboard/customers/$customerId/edit"
    | "/dashboard/deviation/$deviationId/update"
    | "/dashboard/ehs/$ehsId/update"
    | "/dashboard/ehs/$templateId/new"
    | "/dashboard/ehs/template/new"
    | "/dashboard/equipment/$equipmentId/update"
    | "/dashboard/facility/$facilityId/details"
    | "/dashboard/facility/$facilityId/update"
    | "/dashboard/internal-routines/$routineId/update"
    | "/dashboard/location/$resourceId/new"
    | "/dashboard/location/$resourceId/update"
    | "/dashboard/orders/$orderId"
    | "/dashboard/orders/$orderId/report"
    | "/dashboard/orders/$orderId/update"
    | "/dashboard/products/$productId/update"
    | "/dashboard/products/bundles/new"
    | "/dashboard/project/$projectId"
    | "/dashboard/project/$projectId/report"
    | "/dashboard/resources/$employeeId/update"
    | "/dashboard/sales/direct-sale"
    | "/dashboard/sales/report"
    | "/dashboard/sales/sales-opportunity"
    | "/dashboard/sales/direct-sale/new"
    | "/dashboard/service-contract/$serviceContractId/update"
    | "/dashboard/settings/checklist-template"
    | "/dashboard/settings/company"
    | "/dashboard/settings/documentation-template"
    | "/dashboard/settings/equipment"
    | "/dashboard/settings/integration"
    | "/dashboard/settings/order"
    | "/dashboard/settings/project"
    | "/dashboard/settings/task-template"
    | "/dashboard/settings/video-guide"
    | "/dashboard/settings/project-phases/edit"
    | "/dashboard/customers/$customerId"
    | "/dashboard/invoice/new"
    | "/dashboard/orders/new"
    | "/dashboard/products/new"
    | "/dashboard/project/new"
    | "/dashboard/sales/$salesOpportunityId"
    | "/dashboard/sales/new"
    | "/dashboard/time/new"
    | "/dashboard/customers/$customerId/order/new"
    | "/dashboard/ehs/template/$templateId/update"
    | "/dashboard/orders/$orderId/checklist"
    | "/dashboard/orders/$orderId/deviation"
    | "/dashboard/orders/$orderId/documentation"
    | "/dashboard/orders/$orderId/overview"
    | "/dashboard/orders/$orderId/product"
    | "/dashboard/orders/$orderId/time"
    | "/dashboard/orders/$orderId/products/add"
    | "/dashboard/orders/template/$templateId/product"
    | "/dashboard/orders/template/$templateId/update"
    | "/dashboard/products/bundles/$bundleId/update"
    | "/dashboard/project/$projectId/checklist"
    | "/dashboard/project/$projectId/deviation"
    | "/dashboard/project/$projectId/documentation"
    | "/dashboard/project/$projectId/ehs"
    | "/dashboard/project/$projectId/overview"
    | "/dashboard/project/$projectId/product"
    | "/dashboard/project/$projectId/progress-plan"
    | "/dashboard/project/$projectId/sub-project"
    | "/dashboard/project/$projectId/task"
    | "/dashboard/project/$projectId/products/add"
    | "/dashboard/project/$projectId/task/$taskId"
    | "/dashboard/sales/$salesOpportunityId/offer-file/new"
    | "/dashboard/sales/$salesOpportunityId/offer/new"
    | "/dashboard/sales/inspection/$customerId/new"
    | "/dashboard/sales/inspection/$inspectionId/update"
    | "/dashboard/sales/offer/$offerId/additional-work"
    | "/dashboard/time/approval/order/$orderId"
    | "/dashboard/time/approval/project/$projectId"
    | "/dashboard/time/approval/user/$userId"
    | "/dashboard/orders/template/$templateId"
    | "/dashboard/project/$projectId/phases"
    | "/dashboard/project/$projectId/workers"
    | "/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
    | "/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
    | "/dashboard/project/$projectId/economy/budget"
    | "/dashboard/project/$projectId/economy/change-order"
    | "/dashboard/project/$projectId/economy/contract"
    | "/dashboard/project/$projectId/economy/invoice"
    | "/dashboard/project/$projectId/economy/subcontractor"
    | "/dashboard/project/$projectId/economy/time"
    | "/dashboard/project/$projectId/economy"
  fileRoutesByTo: FileRoutesByTo
  to:
    | ""
    | "/createCompany"
    | "/dashboard"
    | "/invitations"
    | "/trial"
    | "/userInfo"
    | "/welcome"
    | "/createAccount"
    | "/login"
    | "/resetPassword"
    | "/"
    | "/trial/confirmation"
    | "/trial/expired"
    | "/trial/subscribe"
    | "/offer/preview"
    | "/offer/template"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId"
    | "/dashboard/activities/new"
    | "/dashboard/checklist/new"
    | "/dashboard/customers/new"
    | "/dashboard/deviation/new"
    | "/dashboard/equipment/new"
    | "/dashboard/facility/new"
    | "/dashboard/internal-routines/new"
    | "/dashboard/sales"
    | "/dashboard/service-contract/new"
    | "/dashboard/settings"
    | "/offer/$pathUri/details"
    | "/offer/$pathUri/receipt"
    | "/dashboard/activities"
    | "/dashboard/customers"
    | "/dashboard/ehs"
    | "/dashboard/employees"
    | "/dashboard/invoice"
    | "/dashboard/orders"
    | "/dashboard/overview"
    | "/dashboard/prices"
    | "/dashboard/products"
    | "/dashboard/project"
    | "/dashboard/time"
    | "/offer/$pathUri"
    | "/dashboard/activities/$activityId/update"
    | "/dashboard/checklist/$checklistId/update"
    | "/dashboard/customers/$customerId/edit"
    | "/dashboard/deviation/$deviationId/update"
    | "/dashboard/ehs/$ehsId/update"
    | "/dashboard/ehs/$templateId/new"
    | "/dashboard/ehs/template/new"
    | "/dashboard/equipment/$equipmentId/update"
    | "/dashboard/facility/$facilityId/details"
    | "/dashboard/facility/$facilityId/update"
    | "/dashboard/internal-routines/$routineId/update"
    | "/dashboard/location/$resourceId/new"
    | "/dashboard/location/$resourceId/update"
    | "/dashboard/orders/$orderId"
    | "/dashboard/orders/$orderId/report"
    | "/dashboard/orders/$orderId/update"
    | "/dashboard/products/$productId/update"
    | "/dashboard/products/bundles/new"
    | "/dashboard/project/$projectId"
    | "/dashboard/project/$projectId/report"
    | "/dashboard/resources/$employeeId/update"
    | "/dashboard/sales/direct-sale"
    | "/dashboard/sales/report"
    | "/dashboard/sales/sales-opportunity"
    | "/dashboard/sales/direct-sale/new"
    | "/dashboard/service-contract/$serviceContractId/update"
    | "/dashboard/settings/checklist-template"
    | "/dashboard/settings/company"
    | "/dashboard/settings/documentation-template"
    | "/dashboard/settings/equipment"
    | "/dashboard/settings/integration"
    | "/dashboard/settings/order"
    | "/dashboard/settings/project"
    | "/dashboard/settings/task-template"
    | "/dashboard/settings/video-guide"
    | "/dashboard/settings/project-phases/edit"
    | "/dashboard/customers/$customerId"
    | "/dashboard/invoice/new"
    | "/dashboard/orders/new"
    | "/dashboard/products/new"
    | "/dashboard/project/new"
    | "/dashboard/sales/$salesOpportunityId"
    | "/dashboard/sales/new"
    | "/dashboard/time/new"
    | "/dashboard/customers/$customerId/order/new"
    | "/dashboard/ehs/template/$templateId/update"
    | "/dashboard/orders/$orderId/checklist"
    | "/dashboard/orders/$orderId/deviation"
    | "/dashboard/orders/$orderId/documentation"
    | "/dashboard/orders/$orderId/overview"
    | "/dashboard/orders/$orderId/product"
    | "/dashboard/orders/$orderId/time"
    | "/dashboard/orders/$orderId/products/add"
    | "/dashboard/orders/template/$templateId/product"
    | "/dashboard/orders/template/$templateId/update"
    | "/dashboard/products/bundles/$bundleId/update"
    | "/dashboard/project/$projectId/checklist"
    | "/dashboard/project/$projectId/deviation"
    | "/dashboard/project/$projectId/documentation"
    | "/dashboard/project/$projectId/ehs"
    | "/dashboard/project/$projectId/overview"
    | "/dashboard/project/$projectId/product"
    | "/dashboard/project/$projectId/progress-plan"
    | "/dashboard/project/$projectId/sub-project"
    | "/dashboard/project/$projectId/task"
    | "/dashboard/project/$projectId/products/add"
    | "/dashboard/project/$projectId/task/$taskId"
    | "/dashboard/sales/$salesOpportunityId/offer-file/new"
    | "/dashboard/sales/$salesOpportunityId/offer/new"
    | "/dashboard/sales/inspection/$customerId/new"
    | "/dashboard/sales/inspection/$inspectionId/update"
    | "/dashboard/sales/offer/$offerId/additional-work"
    | "/dashboard/time/approval/order/$orderId"
    | "/dashboard/time/approval/project/$projectId"
    | "/dashboard/time/approval/user/$userId"
    | "/dashboard/orders/template/$templateId"
    | "/dashboard/project/$projectId/phases"
    | "/dashboard/project/$projectId/workers"
    | "/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
    | "/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
    | "/dashboard/project/$projectId/economy/budget"
    | "/dashboard/project/$projectId/economy/change-order"
    | "/dashboard/project/$projectId/economy/contract"
    | "/dashboard/project/$projectId/economy/invoice"
    | "/dashboard/project/$projectId/economy/subcontractor"
    | "/dashboard/project/$projectId/economy/time"
    | "/dashboard/project/$projectId/economy"
  id:
    | "__root__"
    | "/_protected"
    | "/_unauthenticated"
    | "/_protected/createCompany"
    | "/_protected/dashboard"
    | "/_protected/invitations"
    | "/_protected/trial"
    | "/_protected/userInfo"
    | "/_protected/welcome"
    | "/_unauthenticated/createAccount"
    | "/_unauthenticated/login"
    | "/_unauthenticated/resetPassword"
    | "/_unauthenticated/"
    | "/_protected/trial/confirmation"
    | "/_protected/trial/expired"
    | "/_protected/trial/subscribe"
    | "/_unauthenticated/offer/preview"
    | "/_unauthenticated/offer/template"
    | "/guest/order/$orderId"
    | "/guest/project/$projectId"
    | "/guest/$customerId/"
    | "/_protected/dashboard/activities/new"
    | "/_protected/dashboard/checklist/new"
    | "/_protected/dashboard/customers/new"
    | "/_protected/dashboard/deviation/new"
    | "/_protected/dashboard/equipment/new"
    | "/_protected/dashboard/facility/new"
    | "/_protected/dashboard/internal-routines/new"
    | "/_protected/dashboard/sales"
    | "/_protected/dashboard/sales/_layout"
    | "/_protected/dashboard/service-contract/new"
    | "/_protected/dashboard/settings"
    | "/_protected/dashboard/settings/_layout"
    | "/_unauthenticated/offer/$pathUri/details"
    | "/_unauthenticated/offer/$pathUri/receipt"
    | "/_protected/dashboard/activities/"
    | "/_protected/dashboard/customers/"
    | "/_protected/dashboard/ehs/"
    | "/_protected/dashboard/employees/"
    | "/_protected/dashboard/invoice/"
    | "/_protected/dashboard/orders/"
    | "/_protected/dashboard/overview/"
    | "/_protected/dashboard/prices/"
    | "/_protected/dashboard/products/"
    | "/_protected/dashboard/project/"
    | "/_protected/dashboard/time/"
    | "/_unauthenticated/offer/$pathUri/"
    | "/_protected/dashboard/activities/$activityId/update"
    | "/_protected/dashboard/checklist/$checklistId/update"
    | "/_protected/dashboard/customers/$customerId/edit"
    | "/_protected/dashboard/deviation/$deviationId/update"
    | "/_protected/dashboard/ehs/$ehsId/update"
    | "/_protected/dashboard/ehs/$templateId/new"
    | "/_protected/dashboard/ehs/template/new"
    | "/_protected/dashboard/equipment/$equipmentId/update"
    | "/_protected/dashboard/facility/$facilityId/details"
    | "/_protected/dashboard/facility/$facilityId/update"
    | "/_protected/dashboard/internal-routines/$routineId/update"
    | "/_protected/dashboard/location/$resourceId/new"
    | "/_protected/dashboard/location/$resourceId/update"
    | "/_protected/dashboard/orders/$orderId"
    | "/_protected/dashboard/orders/$orderId/_layout"
    | "/_protected/dashboard/orders/$orderId/report"
    | "/_protected/dashboard/orders/$orderId/update"
    | "/_protected/dashboard/products/$productId/update"
    | "/_protected/dashboard/products/bundles/new"
    | "/_protected/dashboard/project/$projectId"
    | "/_protected/dashboard/project/$projectId/_layout"
    | "/_protected/dashboard/project/$projectId/report"
    | "/_protected/dashboard/resources/$employeeId/update"
    | "/_protected/dashboard/sales/_layout/direct-sale"
    | "/_protected/dashboard/sales/_layout/report"
    | "/_protected/dashboard/sales/_layout/sales-opportunity"
    | "/_protected/dashboard/sales/direct-sale/new"
    | "/_protected/dashboard/service-contract/$serviceContractId/update"
    | "/_protected/dashboard/settings/_layout/checklist-template"
    | "/_protected/dashboard/settings/_layout/company"
    | "/_protected/dashboard/settings/_layout/documentation-template"
    | "/_protected/dashboard/settings/_layout/equipment"
    | "/_protected/dashboard/settings/_layout/integration"
    | "/_protected/dashboard/settings/_layout/order"
    | "/_protected/dashboard/settings/_layout/project"
    | "/_protected/dashboard/settings/_layout/task-template"
    | "/_protected/dashboard/settings/_layout/video-guide"
    | "/_protected/dashboard/settings/project-phases/edit"
    | "/_protected/dashboard/customers/$customerId/"
    | "/_protected/dashboard/invoice/new/"
    | "/_protected/dashboard/orders/new/"
    | "/_protected/dashboard/products/new/"
    | "/_protected/dashboard/project/new/"
    | "/_protected/dashboard/sales/$salesOpportunityId/"
    | "/_protected/dashboard/sales/new/"
    | "/_protected/dashboard/time/new/"
    | "/_protected/dashboard/customers/$customerId/order/new"
    | "/_protected/dashboard/ehs/template/$templateId/update"
    | "/_protected/dashboard/orders/$orderId/_layout/checklist"
    | "/_protected/dashboard/orders/$orderId/_layout/deviation"
    | "/_protected/dashboard/orders/$orderId/_layout/documentation"
    | "/_protected/dashboard/orders/$orderId/_layout/overview"
    | "/_protected/dashboard/orders/$orderId/_layout/product"
    | "/_protected/dashboard/orders/$orderId/_layout/time"
    | "/_protected/dashboard/orders/$orderId/products/add"
    | "/_protected/dashboard/orders/template/$templateId/product"
    | "/_protected/dashboard/orders/template/$templateId/update"
    | "/_protected/dashboard/products/bundles/$bundleId/update"
    | "/_protected/dashboard/project/$projectId/_layout/_layout"
    | "/_protected/dashboard/project/$projectId/_layout/checklist"
    | "/_protected/dashboard/project/$projectId/_layout/deviation"
    | "/_protected/dashboard/project/$projectId/_layout/documentation"
    | "/_protected/dashboard/project/$projectId/_layout/ehs"
    | "/_protected/dashboard/project/$projectId/_layout/overview"
    | "/_protected/dashboard/project/$projectId/_layout/product"
    | "/_protected/dashboard/project/$projectId/_layout/progress-plan"
    | "/_protected/dashboard/project/$projectId/_layout/sub-project"
    | "/_protected/dashboard/project/$projectId/_layout/task"
    | "/_protected/dashboard/project/$projectId/products/add"
    | "/_protected/dashboard/project/$projectId/task/$taskId"
    | "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new"
    | "/_protected/dashboard/sales/$salesOpportunityId/offer/new"
    | "/_protected/dashboard/sales/inspection/$customerId/new"
    | "/_protected/dashboard/sales/inspection/$inspectionId/update"
    | "/_protected/dashboard/sales/offer/$offerId/additional-work"
    | "/_protected/dashboard/time/approval/order/$orderId"
    | "/_protected/dashboard/time/approval/project/$projectId"
    | "/_protected/dashboard/time/approval/user/$userId"
    | "/_protected/dashboard/orders/template/$templateId/"
    | "/_protected/dashboard/project/$projectId/phases/"
    | "/_protected/dashboard/project/$projectId/workers/"
    | "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update"
    | "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time"
    | "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  ProtectedRoute: typeof ProtectedRouteWithChildren
  UnauthenticatedRoute: typeof UnauthenticatedRouteWithChildren
  GuestOrderOrderIdRoute: typeof GuestOrderOrderIdRoute
  GuestProjectProjectIdRoute: typeof GuestProjectProjectIdRoute
  GuestCustomerIdIndexRoute: typeof GuestCustomerIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  ProtectedRoute: ProtectedRouteWithChildren,
  UnauthenticatedRoute: UnauthenticatedRouteWithChildren,
  GuestOrderOrderIdRoute: GuestOrderOrderIdRoute,
  GuestProjectProjectIdRoute: GuestProjectProjectIdRoute,
  GuestCustomerIdIndexRoute: GuestCustomerIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_protected",
        "/_unauthenticated",
        "/guest/order/$orderId",
        "/guest/project/$projectId",
        "/guest/$customerId/"
      ]
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/createCompany",
        "/_protected/dashboard",
        "/_protected/invitations",
        "/_protected/trial",
        "/_protected/userInfo",
        "/_protected/welcome"
      ]
    },
    "/_unauthenticated": {
      "filePath": "_unauthenticated.tsx",
      "children": [
        "/_unauthenticated/createAccount",
        "/_unauthenticated/login",
        "/_unauthenticated/resetPassword",
        "/_unauthenticated/",
        "/_unauthenticated/offer/preview",
        "/_unauthenticated/offer/template",
        "/_unauthenticated/offer/$pathUri/details",
        "/_unauthenticated/offer/$pathUri/receipt",
        "/_unauthenticated/offer/$pathUri/"
      ]
    },
    "/_protected/createCompany": {
      "filePath": "_protected/createCompany.tsx",
      "parent": "/_protected"
    },
    "/_protected/dashboard": {
      "filePath": "_protected/dashboard.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/dashboard/activities/new",
        "/_protected/dashboard/checklist/new",
        "/_protected/dashboard/customers/new",
        "/_protected/dashboard/deviation/new",
        "/_protected/dashboard/equipment/new",
        "/_protected/dashboard/facility/new",
        "/_protected/dashboard/internal-routines/new",
        "/_protected/dashboard/sales",
        "/_protected/dashboard/service-contract/new",
        "/_protected/dashboard/settings",
        "/_protected/dashboard/activities/",
        "/_protected/dashboard/customers/",
        "/_protected/dashboard/ehs/",
        "/_protected/dashboard/employees/",
        "/_protected/dashboard/invoice/",
        "/_protected/dashboard/orders/",
        "/_protected/dashboard/overview/",
        "/_protected/dashboard/prices/",
        "/_protected/dashboard/products/",
        "/_protected/dashboard/project/",
        "/_protected/dashboard/time/",
        "/_protected/dashboard/activities/$activityId/update",
        "/_protected/dashboard/checklist/$checklistId/update",
        "/_protected/dashboard/customers/$customerId/edit",
        "/_protected/dashboard/deviation/$deviationId/update",
        "/_protected/dashboard/ehs/$ehsId/update",
        "/_protected/dashboard/ehs/$templateId/new",
        "/_protected/dashboard/ehs/template/new",
        "/_protected/dashboard/equipment/$equipmentId/update",
        "/_protected/dashboard/facility/$facilityId/details",
        "/_protected/dashboard/facility/$facilityId/update",
        "/_protected/dashboard/internal-routines/$routineId/update",
        "/_protected/dashboard/location/$resourceId/new",
        "/_protected/dashboard/location/$resourceId/update",
        "/_protected/dashboard/orders/$orderId",
        "/_protected/dashboard/products/$productId/update",
        "/_protected/dashboard/products/bundles/new",
        "/_protected/dashboard/project/$projectId",
        "/_protected/dashboard/resources/$employeeId/update",
        "/_protected/dashboard/service-contract/$serviceContractId/update",
        "/_protected/dashboard/customers/$customerId/",
        "/_protected/dashboard/invoice/new/",
        "/_protected/dashboard/orders/new/",
        "/_protected/dashboard/products/new/",
        "/_protected/dashboard/project/new/",
        "/_protected/dashboard/time/new/",
        "/_protected/dashboard/customers/$customerId/order/new",
        "/_protected/dashboard/ehs/template/$templateId/update",
        "/_protected/dashboard/orders/template/$templateId/product",
        "/_protected/dashboard/orders/template/$templateId/update",
        "/_protected/dashboard/products/bundles/$bundleId/update",
        "/_protected/dashboard/time/approval/order/$orderId",
        "/_protected/dashboard/time/approval/project/$projectId",
        "/_protected/dashboard/time/approval/user/$userId",
        "/_protected/dashboard/orders/template/$templateId/"
      ]
    },
    "/_protected/invitations": {
      "filePath": "_protected/invitations.tsx",
      "parent": "/_protected"
    },
    "/_protected/trial": {
      "filePath": "_protected/trial.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/trial/confirmation",
        "/_protected/trial/expired",
        "/_protected/trial/subscribe"
      ]
    },
    "/_protected/userInfo": {
      "filePath": "_protected/userInfo.tsx",
      "parent": "/_protected"
    },
    "/_protected/welcome": {
      "filePath": "_protected/welcome.tsx",
      "parent": "/_protected"
    },
    "/_unauthenticated/createAccount": {
      "filePath": "_unauthenticated/createAccount.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/resetPassword": {
      "filePath": "_unauthenticated/resetPassword.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/": {
      "filePath": "_unauthenticated/index.tsx",
      "parent": "/_unauthenticated"
    },
    "/_protected/trial/confirmation": {
      "filePath": "_protected/trial/confirmation.tsx",
      "parent": "/_protected/trial"
    },
    "/_protected/trial/expired": {
      "filePath": "_protected/trial/expired.tsx",
      "parent": "/_protected/trial"
    },
    "/_protected/trial/subscribe": {
      "filePath": "_protected/trial/subscribe.tsx",
      "parent": "/_protected/trial"
    },
    "/_unauthenticated/offer/preview": {
      "filePath": "_unauthenticated/offer/preview.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/offer/template": {
      "filePath": "_unauthenticated/offer/template.tsx",
      "parent": "/_unauthenticated"
    },
    "/guest/order/$orderId": {
      "filePath": "guest/order/$orderId.tsx"
    },
    "/guest/project/$projectId": {
      "filePath": "guest/project/$projectId.tsx"
    },
    "/guest/$customerId/": {
      "filePath": "guest/$customerId/index.tsx"
    },
    "/_protected/dashboard/activities/new": {
      "filePath": "_protected/dashboard/activities/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/checklist/new": {
      "filePath": "_protected/dashboard/checklist/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers/new": {
      "filePath": "_protected/dashboard/customers/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/deviation/new": {
      "filePath": "_protected/dashboard/deviation/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/equipment/new": {
      "filePath": "_protected/dashboard/equipment/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/new": {
      "filePath": "_protected/dashboard/facility/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/internal-routines/new": {
      "filePath": "_protected/dashboard/internal-routines/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/sales": {
      "filePath": "_protected/dashboard/sales",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/sales/_layout",
        "/_protected/dashboard/sales/direct-sale/new",
        "/_protected/dashboard/sales/$salesOpportunityId/",
        "/_protected/dashboard/sales/new/",
        "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new",
        "/_protected/dashboard/sales/$salesOpportunityId/offer/new",
        "/_protected/dashboard/sales/inspection/$customerId/new",
        "/_protected/dashboard/sales/inspection/$inspectionId/update",
        "/_protected/dashboard/sales/offer/$offerId/additional-work",
        "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update",
        "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update"
      ]
    },
    "/_protected/dashboard/sales/_layout": {
      "filePath": "_protected/dashboard/sales/_layout.tsx",
      "parent": "/_protected/dashboard/sales",
      "children": [
        "/_protected/dashboard/sales/_layout/direct-sale",
        "/_protected/dashboard/sales/_layout/report",
        "/_protected/dashboard/sales/_layout/sales-opportunity"
      ]
    },
    "/_protected/dashboard/service-contract/new": {
      "filePath": "_protected/dashboard/service-contract/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/settings": {
      "filePath": "_protected/dashboard/settings",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/settings/_layout",
        "/_protected/dashboard/settings/project-phases/edit"
      ]
    },
    "/_protected/dashboard/settings/_layout": {
      "filePath": "_protected/dashboard/settings/_layout.tsx",
      "parent": "/_protected/dashboard/settings",
      "children": [
        "/_protected/dashboard/settings/_layout/checklist-template",
        "/_protected/dashboard/settings/_layout/company",
        "/_protected/dashboard/settings/_layout/documentation-template",
        "/_protected/dashboard/settings/_layout/equipment",
        "/_protected/dashboard/settings/_layout/integration",
        "/_protected/dashboard/settings/_layout/order",
        "/_protected/dashboard/settings/_layout/project",
        "/_protected/dashboard/settings/_layout/task-template",
        "/_protected/dashboard/settings/_layout/video-guide"
      ]
    },
    "/_unauthenticated/offer/$pathUri/details": {
      "filePath": "_unauthenticated/offer/$pathUri/details.tsx",
      "parent": "/_unauthenticated"
    },
    "/_unauthenticated/offer/$pathUri/receipt": {
      "filePath": "_unauthenticated/offer/$pathUri/receipt.tsx",
      "parent": "/_unauthenticated"
    },
    "/_protected/dashboard/activities/": {
      "filePath": "_protected/dashboard/activities/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers/": {
      "filePath": "_protected/dashboard/customers/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/": {
      "filePath": "_protected/dashboard/ehs/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/employees/": {
      "filePath": "_protected/dashboard/employees/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/invoice/": {
      "filePath": "_protected/dashboard/invoice/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/": {
      "filePath": "_protected/dashboard/orders/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/overview/": {
      "filePath": "_protected/dashboard/overview/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/prices/": {
      "filePath": "_protected/dashboard/prices/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/": {
      "filePath": "_protected/dashboard/products/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/": {
      "filePath": "_protected/dashboard/project/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/time/": {
      "filePath": "_protected/dashboard/time/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_unauthenticated/offer/$pathUri/": {
      "filePath": "_unauthenticated/offer/$pathUri/index.tsx",
      "parent": "/_unauthenticated"
    },
    "/_protected/dashboard/activities/$activityId/update": {
      "filePath": "_protected/dashboard/activities/$activityId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/checklist/$checklistId/update": {
      "filePath": "_protected/dashboard/checklist/$checklistId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers/$customerId/edit": {
      "filePath": "_protected/dashboard/customers/$customerId/edit.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/deviation/$deviationId/update": {
      "filePath": "_protected/dashboard/deviation/$deviationId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/$ehsId/update": {
      "filePath": "_protected/dashboard/ehs/$ehsId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/$templateId/new": {
      "filePath": "_protected/dashboard/ehs/$templateId/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/template/new": {
      "filePath": "_protected/dashboard/ehs/template/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/equipment/$equipmentId/update": {
      "filePath": "_protected/dashboard/equipment/$equipmentId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/$facilityId/details": {
      "filePath": "_protected/dashboard/facility/$facilityId/details.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/facility/$facilityId/update": {
      "filePath": "_protected/dashboard/facility/$facilityId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/internal-routines/$routineId/update": {
      "filePath": "_protected/dashboard/internal-routines/$routineId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/location/$resourceId/new": {
      "filePath": "_protected/dashboard/location/$resourceId/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/location/$resourceId/update": {
      "filePath": "_protected/dashboard/location/$resourceId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/$orderId": {
      "filePath": "_protected/dashboard/orders/$orderId",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/orders/$orderId/_layout",
        "/_protected/dashboard/orders/$orderId/report",
        "/_protected/dashboard/orders/$orderId/update",
        "/_protected/dashboard/orders/$orderId/products/add"
      ]
    },
    "/_protected/dashboard/orders/$orderId/_layout": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout.tsx",
      "parent": "/_protected/dashboard/orders/$orderId",
      "children": [
        "/_protected/dashboard/orders/$orderId/_layout/checklist",
        "/_protected/dashboard/orders/$orderId/_layout/deviation",
        "/_protected/dashboard/orders/$orderId/_layout/documentation",
        "/_protected/dashboard/orders/$orderId/_layout/overview",
        "/_protected/dashboard/orders/$orderId/_layout/product",
        "/_protected/dashboard/orders/$orderId/_layout/time"
      ]
    },
    "/_protected/dashboard/orders/$orderId/report": {
      "filePath": "_protected/dashboard/orders/$orderId/report.tsx",
      "parent": "/_protected/dashboard/orders/$orderId"
    },
    "/_protected/dashboard/orders/$orderId/update": {
      "filePath": "_protected/dashboard/orders/$orderId/update.tsx",
      "parent": "/_protected/dashboard/orders/$orderId"
    },
    "/_protected/dashboard/products/$productId/update": {
      "filePath": "_protected/dashboard/products/$productId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/bundles/new": {
      "filePath": "_protected/dashboard/products/bundles/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId": {
      "filePath": "_protected/dashboard/project/$projectId",
      "parent": "/_protected/dashboard",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout",
        "/_protected/dashboard/project/$projectId/report",
        "/_protected/dashboard/project/$projectId/products/add",
        "/_protected/dashboard/project/$projectId/task/$taskId",
        "/_protected/dashboard/project/$projectId/phases/",
        "/_protected/dashboard/project/$projectId/workers/"
      ]
    },
    "/_protected/dashboard/project/$projectId/_layout": {
      "filePath": "_protected/dashboard/project/$projectId/_layout.tsx",
      "parent": "/_protected/dashboard/project/$projectId",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout/_layout",
        "/_protected/dashboard/project/$projectId/_layout/checklist",
        "/_protected/dashboard/project/$projectId/_layout/deviation",
        "/_protected/dashboard/project/$projectId/_layout/documentation",
        "/_protected/dashboard/project/$projectId/_layout/ehs",
        "/_protected/dashboard/project/$projectId/_layout/overview",
        "/_protected/dashboard/project/$projectId/_layout/product",
        "/_protected/dashboard/project/$projectId/_layout/progress-plan",
        "/_protected/dashboard/project/$projectId/_layout/sub-project",
        "/_protected/dashboard/project/$projectId/_layout/task"
      ]
    },
    "/_protected/dashboard/project/$projectId/report": {
      "filePath": "_protected/dashboard/project/$projectId/report.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/resources/$employeeId/update": {
      "filePath": "_protected/dashboard/resources/$employeeId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/sales/_layout/direct-sale": {
      "filePath": "_protected/dashboard/sales/_layout/direct-sale.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/_layout/report": {
      "filePath": "_protected/dashboard/sales/_layout/report.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/_layout/sales-opportunity": {
      "filePath": "_protected/dashboard/sales/_layout/sales-opportunity.tsx",
      "parent": "/_protected/dashboard/sales/_layout"
    },
    "/_protected/dashboard/sales/direct-sale/new": {
      "filePath": "_protected/dashboard/sales/direct-sale/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/service-contract/$serviceContractId/update": {
      "filePath": "_protected/dashboard/service-contract/$serviceContractId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/settings/_layout/checklist-template": {
      "filePath": "_protected/dashboard/settings/_layout/checklist-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/company": {
      "filePath": "_protected/dashboard/settings/_layout/company.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/documentation-template": {
      "filePath": "_protected/dashboard/settings/_layout/documentation-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/equipment": {
      "filePath": "_protected/dashboard/settings/_layout/equipment.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/integration": {
      "filePath": "_protected/dashboard/settings/_layout/integration.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/order": {
      "filePath": "_protected/dashboard/settings/_layout/order.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/project": {
      "filePath": "_protected/dashboard/settings/_layout/project.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/task-template": {
      "filePath": "_protected/dashboard/settings/_layout/task-template.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/_layout/video-guide": {
      "filePath": "_protected/dashboard/settings/_layout/video-guide.tsx",
      "parent": "/_protected/dashboard/settings/_layout"
    },
    "/_protected/dashboard/settings/project-phases/edit": {
      "filePath": "_protected/dashboard/settings/project-phases/edit.tsx",
      "parent": "/_protected/dashboard/settings"
    },
    "/_protected/dashboard/customers/$customerId/": {
      "filePath": "_protected/dashboard/customers/$customerId/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/invoice/new/": {
      "filePath": "_protected/dashboard/invoice/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/new/": {
      "filePath": "_protected/dashboard/orders/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/new/": {
      "filePath": "_protected/dashboard/products/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/new/": {
      "filePath": "_protected/dashboard/project/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/sales/$salesOpportunityId/": {
      "filePath": "_protected/dashboard/sales/$salesOpportunityId/index.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/new/": {
      "filePath": "_protected/dashboard/sales/new/index.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/time/new/": {
      "filePath": "_protected/dashboard/time/new/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/customers/$customerId/order/new": {
      "filePath": "_protected/dashboard/customers/$customerId/order/new.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/ehs/template/$templateId/update": {
      "filePath": "_protected/dashboard/ehs/template/$templateId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/$orderId/_layout/checklist": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/checklist.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/deviation": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/deviation.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/documentation": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/documentation.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/overview": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/overview.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/product": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/product.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/_layout/time": {
      "filePath": "_protected/dashboard/orders/$orderId/_layout/time.tsx",
      "parent": "/_protected/dashboard/orders/$orderId/_layout"
    },
    "/_protected/dashboard/orders/$orderId/products/add": {
      "filePath": "_protected/dashboard/orders/$orderId/products/add.tsx",
      "parent": "/_protected/dashboard/orders/$orderId"
    },
    "/_protected/dashboard/orders/template/$templateId/product": {
      "filePath": "_protected/dashboard/orders/template/$templateId/product.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/template/$templateId/update": {
      "filePath": "_protected/dashboard/orders/template/$templateId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/products/bundles/$bundleId/update": {
      "filePath": "_protected/dashboard/products/bundles/$bundleId/update.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout",
      "children": [
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time",
        "/_protected/dashboard/project/$projectId/_layout/_layout/economy/"
      ]
    },
    "/_protected/dashboard/project/$projectId/_layout/checklist": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/checklist.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/deviation": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/deviation.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/documentation": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/documentation.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/ehs": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/ehs.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/overview": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/overview.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/product": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/product.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/progress-plan": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/progress-plan.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/sub-project": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/sub-project.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/task": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/task.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout"
    },
    "/_protected/dashboard/project/$projectId/products/add": {
      "filePath": "_protected/dashboard/project/$projectId/products/add.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/$projectId/task/$taskId": {
      "filePath": "_protected/dashboard/project/$projectId/task/$taskId.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/sales/$salesOpportunityId/offer-file/new": {
      "filePath": "_protected/dashboard/sales/$salesOpportunityId/offer-file/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/$salesOpportunityId/offer/new": {
      "filePath": "_protected/dashboard/sales/$salesOpportunityId/offer/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/inspection/$customerId/new": {
      "filePath": "_protected/dashboard/sales/inspection/$customerId/new.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/inspection/$inspectionId/update": {
      "filePath": "_protected/dashboard/sales/inspection/$inspectionId/update.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/offer/$offerId/additional-work": {
      "filePath": "_protected/dashboard/sales/offer/$offerId/additional-work.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/time/approval/order/$orderId": {
      "filePath": "_protected/dashboard/time/approval/order/$orderId.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/time/approval/project/$projectId": {
      "filePath": "_protected/dashboard/time/approval/project/$projectId.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/time/approval/user/$userId": {
      "filePath": "_protected/dashboard/time/approval/user/$userId.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/orders/template/$templateId/": {
      "filePath": "_protected/dashboard/orders/template/$templateId/index.tsx",
      "parent": "/_protected/dashboard"
    },
    "/_protected/dashboard/project/$projectId/phases/": {
      "filePath": "_protected/dashboard/project/$projectId/phases/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/project/$projectId/workers/": {
      "filePath": "_protected/dashboard/project/$projectId/workers/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId"
    },
    "/_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update": {
      "filePath": "_protected/dashboard/sales/$salesOpportunityId/offer-file/$offerId/update.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update": {
      "filePath": "_protected/dashboard/sales/$salesOpportunityId/offer/$offerId/update.tsx",
      "parent": "/_protected/dashboard/sales"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/budget": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/budget.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/change-order": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/change-order.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/contract": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/contract.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/invoice": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/invoice.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/subcontractor": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/subcontractor.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/time": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/time.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    },
    "/_protected/dashboard/project/$projectId/_layout/_layout/economy/": {
      "filePath": "_protected/dashboard/project/$projectId/_layout/_layout.economy/index.tsx",
      "parent": "/_protected/dashboard/project/$projectId/_layout/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
