import { z } from "zod";
import { queryClient } from "../../router/QueryClient";
import { CustomerV3 } from "../../.generated/api";
import {
  createCustomerV3,
  useGetCustomerById
} from "../../service/api/CustomerApiV3";

export const customerFormSchema = z
  .object({
    isNewCustomer: z.boolean(),
    customerType: z.enum(["private", "company"]),
    customerId: z.string().optional(),
    info: z
      .object({
        companyName: z.string().nullish(),
        orgNumber: z.string().nullish(),
        contactPerson: z.string().optional(),
        email: z.string().optional(),
        phone: z.string().optional(),
        formattedCustomerName: z.string().optional()
      })
      .optional()
  })
  .superRefine((schema, ctx) => {
    if (!schema.isNewCustomer && !schema.customerId) {
      ctx.addIssue({
        code: "invalid_type",
        path: ["customerId"],
        expected: "string",
        received: "undefined"
      });
    }

    if (schema.isNewCustomer) {
      if (schema.customerType === "company") {
        if (!schema.info?.orgNumber) {
          ctx.addIssue({
            code: "invalid_type",
            path: ["info.orgNumber"],
            expected: "string",
            received: "undefined"
          });
        }

        if (!schema.info?.companyName) {
          ctx.addIssue({
            code: "invalid_type",
            path: ["info.companyName"],
            expected: "string",
            received: "undefined"
          });
        }
      } else {
        if (!schema.info?.contactPerson) {
          ctx.addIssue({
            code: "invalid_type",
            path: ["info.contactPerson"],
            expected: "string",
            received: "undefined"
          });
        }
      }
    }
  });

export type CustomerFormData = z.infer<typeof customerFormSchema>;

export function getCustomerDefaultValues(
  existingCustomer?: CustomerV3
): CustomerFormData {
  return {
    isNewCustomer: false,
    customerType: existingCustomer?.type === "Private" ? "private" : "company"
  };
}

export async function handleCustomer(
  data: CustomerFormData,
  companyId: string
) {
  let customer;
  if (data.isNewCustomer) {
    if (data.customerType === "private") {
      customer = await createCustomerV3(companyId, {
        primaryContact: {
          firstName: data.info?.contactPerson ?? "",
          lastName: "",
          email: data.info?.email,
          mobilePhone: data.info?.phone
        }
      });
    } else {
      customer = await createCustomerV3(companyId, {
        company: {
          companyName: data.info?.companyName ?? "",
          orgNumber: data.info?.orgNumber ?? ""
        },
        primaryContact: {
          firstName: data.info?.contactPerson ?? "",
          lastName: "",
          email: data.info?.email,
          mobilePhone: data.info?.phone
        }
      });
    }
  } else {
    customer = await queryClient.fetchQuery(
      useGetCustomerById.getOptions({
        customerId: data.customerId ?? "",
        companyId
      })
    );
  }
  return customer;
}
